import React, { useEffect } from 'react'
import { Loader } from 'semantic-ui-react'
import { debugMessage, cleanup } from './common'

function Logout() {
  useEffect(() => {
    debugMessage('Mounted', 'Logout')
    debugMessage('Logging out ...', 'Logout')
    // signs out and does NOT clears cache)()
    cleanup({ signOut: true, initiator: 'Logout' })
  }, [])

  return (
    <>
      <Loader active />
    </>
  )
}

export { Logout }
