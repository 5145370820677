import moment from 'moment'
import { CognitoAuth } from 'amazon-cognito-auth-js'
import { cleanup, debugMessage } from './common'

const cognito = new CognitoAuth({
  ClientId: '2ps5fmtfnqn08ctjjqekg2ro70',
  AppWebDomain: 'pvpool.auth.us-east-1.amazoncognito.com',
  TokenScopesArray: ['email', 'aws.cognito.signin.user.admin', 'phone', 'openid', 'profile', 'email'],
  RedirectUriSignIn: `${window.location.origin}/login`,
  RedirectUriSignOut: `${window.location.origin}/logout`,
  UserPoolId: 'us-east-1_fUmMKJWwn',
  AdvancedSecurityDataCollectionFlag: 'true'
})

cognito.userhandler = {
  onSuccess: async (result) => {
    debugMessage('cognito.onSuccess', 'authenticator.js')
  },
  onFailure: (err) => {
    debugMessage('cognito.onFailure', 'authenticator.js')
  }
}

const authenticator = {
  async signIn() {
    cognito.getSession()
    await new Promise((resolve) => setTimeout(resolve, 1000))
    return true
  },

  async signOut() {
    cognito.signOut()
    await new Promise((resolve) => setTimeout(resolve, 1000))
  },

  async isAuthenticated() {
    cognito.parseCognitoWebResponse(window.location.href)
    await new Promise((resolve) => setTimeout(resolve, 1000))
    const authed = (await this.isTokenValid()) && cognito.getCurrentUser()
    return authed
  },

  async isTokenValid() {
    const idToken = await this.getToken()

    if (idToken && idToken.exp) {
      const currentTime = moment().format('X')
      const tokenExpiry = idToken.decoded.exp
      return parseInt(currentTime, 10) < parseInt(tokenExpiry, 10)
    }

    return false
  },

  getToken(recoverOnProblem = false) {
    const parseJwt = (token) => {
      if (!token) return null
      var base64Url = token.split('.')[1]
      var base64 = base64Url.replace('-', '+').replace('_', '/')
      return JSON.parse(window.atob(base64))
    }

    // check that a cognito token is in localstorage:
    let itemFound = false
    for (let i in window.localStorage) if (i.includes('CognitoIdentityServiceProvider')) itemFound = true
    if (!itemFound) {
      if (recoverOnProblem) cleanup({ initiator: 'authenticator' })
      return { jwt: null }
    }

    try {
      const jwtToken = cognito.getCachedSession().getIdToken().jwtToken
      const token = {
        jwt: jwtToken,
        decoded: parseJwt(jwtToken)
      }

      if (!token || !token.decoded || !token.decoded.exp) {
        if (recoverOnProblem) cleanup({ initiator: 'authenticator' })
        return { jwt: null }
      }

      return token
    } catch (error) {
      if (recoverOnProblem) cleanup({ initiator: 'authenticator' })
      return { jwt: null }
    }
  },

  getUserDetails() {
    const { decoded } = this.getToken() || {}
    const { name, email } = decoded || {}
    return { name, email }
  }
}

export { authenticator }
