import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Header, Divider, Segment, Message, Form, Icon, Button } from 'semantic-ui-react'
import { ajax } from '../../ajax.js'
import { authenticator } from '../../authenticator.js'
import { debugMessage, queryString, isValidEmailAddress } from '../../common'
import { getEnglishText } from '../../dictionary.js'

import './QuickStart.css'

function QuickStart() {
  const navigate = useNavigate()

  const userEmail = authenticator.getUserDetails()['email']

  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState()

  const [stage, setStage] = useState('start')
  const [existingUnits, setExistingUnits] = useState([])

  const [newAccountForm, setNewAccountForm] = useState({
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    addressCityTown: '',
    postcode: '',
    phoneNumber: '',
    additionalEmail1: '',
    additionalEmail2: '',
    additionalEmail3: ''
  })
  const [addProductForm, setAddProductForm] = useState({
    customName: ''
  })

  const qs = queryString()
  const configId = qs.action.configId
  const hash = qs.action.hash

  const newAccountInputRef = useRef()
  const addProductInputRef = useRef()

  useEffect(() => {
    document.body.classList.remove('initial-load') // make page scrollable in case App.js made it non-scrollable
    debugMessage('Mounted', 'QuickStart')
    fetchUserAuth()
    focusFirstInput()
  }, [])

  function abortQuickStart() {
    debugMessage('Aborting quick start', 'QuickStart')
    navigate('/home')
  }

  function fetchUserAuth() {
    ajax
      .fetchUserAuth(authenticator.getToken())
      .then((response) => {
        const { errorMessage, data } = response

        if (errorMessage === 'pv-email-not-known') {
          setStage('new-account')
          setLoading(false)
          focusFirstInput()
        } else {
          const { units, user } = data

          setExistingUnits(units)

          if (user.isStaff === true || user.isInstaller === true) {
            return abortQuickStart()
          }

          if (units.length > 0) {
            // ignore units this user has access to as a non-primary account email
            const ownUnits = units.filter((unit) => unit.email1 === userEmail)
            if (ownUnits.length > 0) setStage('add-product')
            else setStage('new-account')
          } else setStage('new-account')

          setLoading(false)
          focusFirstInput()
        }
      })
      .catch((err) => {
        setMessage({ negative: true, content: err })
      })
  }

  function submit() {
    setLoading(true)
    setMessage()

    ajax
      .putRaw(authenticator.getToken(), `quickStart`, {
        configId,
        hash,
        newAccountForm,
        addProductForm
      })
      .then((response) => {
        const { result, errorMessage } = response

        if (errorMessage === 'invalid-hash') return abortQuickStart()

        if (result !== 'success') {
          setMessage({ negative: true, content: getEnglishText('quick-start : something-went-wrong') })
          return
        }

        gotoStage('finish')
        setLoading(false)
      })
      .catch((err) => {
        setMessage({ negative: true, content: err })
        setLoading(false)
      })
  }

  function finish() {
    window.localStorage.removeItem('action')
    window.localStorage.removeItem('UNIT_DATA')

    debugMessage('Finishing quick start', 'QuickStart')
    navigate('/home')
  }

  function focusFirstInput() {
    try {
      newAccountInputRef.current.focus()
    } catch (err) {}
    try {
      addProductInputRef.current.focus()
    } catch (err) {}
  }

  function formIsValid() {
    return true
  }

  function gotoStage(stage) {
    setStage(stage)
    setTimeout(focusFirstInput, 1)
  }

  function completeNewAccountStage() {
    function reject(name) {
      setMessage({
        error: true,
        content: (
          <>
            <strong>{name}</strong>&nbsp;&nbsp;{getEnglishText('quick-start : value-is-invalid')}.
          </>
        )
      })
      return
    }

    if (!newAccountForm['firstName']) return reject(getEnglishText('quick-start : first-name'))
    if (!newAccountForm['lastName']) return reject(getEnglishText('quick-start : last-name'))
    if (!newAccountForm['addressLine1']) return reject(getEnglishText('quick-start : address-line-1'))
    if (!newAccountForm['addressCityTown']) return reject(getEnglishText('quick-start : city-town'))
    if (!newAccountForm['postcode']) return reject(getEnglishText('quick-start : postcode'))
    if (!newAccountForm['phoneNumber']) return reject(getEnglishText('quick-start : phone-number'))

    if (newAccountForm['additionalEmail1'] && !isValidEmailAddress(newAccountForm['additionalEmail1'])) return reject('Additional Email 1')
    if (newAccountForm['additionalEmail2'] && !isValidEmailAddress(newAccountForm['additionalEmail2'])) return reject('Additional Email 2')
    if (newAccountForm['additionalEmail3'] && !isValidEmailAddress(newAccountForm['additionalEmail3'])) return reject('Additional Email 3')

    // form is valid
    setMessage()
    gotoStage('add-product')
  }

  function completeAddProductStage() {
    // all fields are currently optional
    // form is valid
    setMessage()
    submit()
  }

  function onFieldChange(e) {
    switch (stage) {
      case 'new-account':
        setNewAccountForm({ ...newAccountForm, [e.currentTarget.id]: e.currentTarget.value })
        break
      case 'add-product':
        setAddProductForm({ ...addProductForm, [e.currentTarget.id]: e.currentTarget.value })
        break
      default:
        return
    }
  }

  return (
    <div id="QuickStart">
      <Container>
        <Divider hidden />

        <Divider hidden />
        {stage === 'start' && (
          <>
            <Header as="h1" inverted>
              {getEnglishText('common : loading')}
            </Header>
            <Segment loading>
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
            </Segment>
          </>
        )}
        {stage === 'new-account' && (
          <>
            <Header as="h1" inverted>
              {getEnglishText('quick-start : new-account')}
              <Header.Subheader>{getEnglishText('quick-start : create-your-new-portal-account')}</Header.Subheader>
            </Header>
            <Segment>
              {message && <Message {...message} />}
              <Form>
                <Header as="h2">{getEnglishText('quick-start : about-you')}</Header>
                <Form.Field>
                  <label>{getEnglishText('quick-start : first-name')}</label>
                  <input
                    id="firstName"
                    disabled={loading}
                    ref={newAccountInputRef}
                    placeholder={getEnglishText('quick-start : first-name')}
                    onChange={onFieldChange}
                    value={newAccountForm['firstName']}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{getEnglishText('quick-start : last-name')}</label>
                  <input
                    id="lastName"
                    disabled={loading}
                    placeholder={getEnglishText('quick-start : last-name')}
                    onChange={onFieldChange}
                    value={newAccountForm['lastName']}
                  />
                </Form.Field>

                <Header as="h2">{getEnglishText('quick-start : install-location')}</Header>
                <Form.Field>
                  <label>{getEnglishText('quick-start : address-line-1')}</label>
                  <input
                    id="addressLine1"
                    disabled={loading}
                    placeholder={getEnglishText('quick-start : address-line-1')}
                    onChange={onFieldChange}
                    value={newAccountForm['addressLine1']}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{getEnglishText('quick-start : address-line-2')}</label>
                  <input
                    id="addressLine2"
                    disabled={loading}
                    placeholder={getEnglishText('quick-start : address-line-2')}
                    onChange={onFieldChange}
                    value={newAccountForm['addressLine2']}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{getEnglishText('quick-start : city-town')}</label>
                  <input
                    id="addressCityTown"
                    disabled={loading}
                    placeholder={getEnglishText('quick-start : city-town')}
                    onChange={onFieldChange}
                    value={newAccountForm['addressCityTown']}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{getEnglishText('quick-start : postcode')}</label>
                  <input
                    id="postcode"
                    disabled={loading}
                    placeholder={getEnglishText('quick-start : postcode')}
                    onChange={onFieldChange}
                    value={newAccountForm['postcode']}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{getEnglishText('quick-start : phone-number')}</label>
                  <input
                    id="phoneNumber"
                    disabled={loading}
                    placeholder={getEnglishText('quick-start : phone-number')}
                    onChange={onFieldChange}
                    value={newAccountForm['phoneNumber']}
                  />
                </Form.Field>

                <Header as="h2">{getEnglishText('quick-start : additional-users')}</Header>
                {getEnglishText('quick-start : additional-users-description')}

                <Form.Field>
                  <label>
                    Additional Email 1&nbsp;&nbsp;<span style={{ opacity: 0.4 }}>(Optional)</span>
                  </label>
                  <input
                    id="additionalEmail1"
                    disabled={loading}
                    placeholder={getEnglishText('quick-start : additional-email')}
                    onChange={onFieldChange}
                    value={newAccountForm['additionalEmail1']}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    Additional Email 2&nbsp;&nbsp;<span style={{ opacity: 0.4 }}>(Optional)</span>
                  </label>
                  <input
                    id="additionalEmail2"
                    disabled={loading}
                    placeholder={getEnglishText('quick-start : additional-email')}
                    onChange={onFieldChange}
                    value={newAccountForm['additionalEmail2']}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    Additional Email 3&nbsp;&nbsp;<span style={{ opacity: 0.4 }}>(Optional)</span>
                  </label>
                  <input
                    id="additionalEmail3"
                    disabled={loading}
                    placeholder={getEnglishText('quick-start : additional-email')}
                    onChange={onFieldChange}
                    value={newAccountForm['additionalEmail3']}
                  />
                </Form.Field>
                <Divider hidden />
                <div style={{ textAlign: 'right' }}>
                  <Button labelPosition="right" icon primary disabled={!formIsValid()} onClick={completeNewAccountStage}>
                    <Icon name="arrow right" />
                    {getEnglishText('common : continue')}
                  </Button>
                </div>
              </Form>
            </Segment>
          </>
        )}
        {stage === 'add-product' && (
          <>
            <Header as="h1" inverted>
              {getEnglishText('quick-start : add-product')}
              <Header.Subheader>{getEnglishText('quick-start : add-product-description')}</Header.Subheader>
            </Header>
            <Segment>
              {message && <Message {...message} />}
              <Form>
                <Header as="h2">{getEnglishText('quick-start : personalise')}</Header>
                {getEnglishText('quick-start : personalise-description')}
                <Form.Field>
                  <label>{getEnglishText('quick-start : custom-name')}</label>
                  <input
                    id="customName"
                    disabled={loading}
                    ref={addProductInputRef}
                    placeholder={getEnglishText('quick-start : custom-name-placeholder')}
                    onChange={onFieldChange}
                    value={addProductForm['customName']}
                  />
                </Form.Field>
                <Divider hidden />
                <div style={{ textAlign: 'right' }}>
                  {!existingUnits.length && (
                    <>
                      <Button labelPosition="left" icon disabled={!formIsValid()} onClick={() => gotoStage('new-account')}>
                        <Icon name="arrow left" />
                        {getEnglishText('common : back')}
                      </Button>
                      &nbsp;&nbsp;
                    </>
                  )}
                  <Button labelPosition="right" icon positive disabled={!formIsValid()} onClick={completeAddProductStage}>
                    <Icon name="check" />
                    {getEnglishText('common : finish')}
                  </Button>
                </div>
              </Form>
            </Segment>
          </>
        )}
        {stage === 'finish' && (
          <>
            <Header as="h1" inverted>
              {getEnglishText('quick-start : all-done')}
              <Header.Subheader>{getEnglishText('quick-start : all-done-description')}</Header.Subheader>
            </Header>
            <Segment>
              <p>{getEnglishText('quick-start : continue-to-portal')}</p>
              <div style={{ textAlign: 'right' }}>
                <Button labelPosition="right" icon positive onClick={finish}>
                  <Icon name="arrow right" />
                  {getEnglishText('common : continue')}
                </Button>
              </div>
            </Segment>
          </>
        )}
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
      </Container>
    </div>
  )
}

export { QuickStart }
