module.exports = {
  "soak-charge-first": {
    monday: [
      { start: "00:00:00", end: "01:59:59", state: "force-charge", id: "1" },
      { start: "02:00:00", end: "04:59:59", state: "force-discharge", id: "2" },
      { start: "05:00:00", end: "05:59:59", state: "disable", id: "3" },
      { start: "06:00:00", end: "09:59:59", state: "force-charge", id: "4" },
      { start: "10:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "17:59:59", state: "force-charge", id: "6" },
      { start: "18:00:00", end: "20:59:59", state: "force-discharge", id: "7" },
      { start: "21:00:00", end: "21:59:59", state: "disable", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-charge", id: "9" },
    ],
    tuesday: [
      { start: "00:00:00", end: "01:59:59", state: "force-charge", id: "1" },
      { start: "02:00:00", end: "04:59:59", state: "force-discharge", id: "2" },
      { start: "05:00:00", end: "05:59:59", state: "disable", id: "3" },
      { start: "06:00:00", end: "09:59:59", state: "force-charge", id: "4" },
      { start: "10:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "17:59:59", state: "force-charge", id: "6" },
      { start: "18:00:00", end: "20:59:59", state: "force-discharge", id: "7" },
      { start: "21:00:00", end: "21:59:59", state: "disable", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-charge", id: "9" },
    ],
    wednesday: [
      { start: "00:00:00", end: "01:59:59", state: "force-charge", id: "1" },
      { start: "02:00:00", end: "04:59:59", state: "force-discharge", id: "2" },
      { start: "05:00:00", end: "05:59:59", state: "disable", id: "3" },
      { start: "06:00:00", end: "09:59:59", state: "force-charge", id: "4" },
      { start: "10:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "17:59:59", state: "force-charge", id: "6" },
      { start: "18:00:00", end: "20:59:59", state: "force-discharge", id: "7" },
      { start: "21:00:00", end: "21:59:59", state: "disable", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-charge", id: "9" },
    ],
    thursday: [
      { start: "00:00:00", end: "01:59:59", state: "force-charge", id: "1" },
      { start: "02:00:00", end: "04:59:59", state: "force-discharge", id: "2" },
      { start: "05:00:00", end: "05:59:59", state: "disable", id: "3" },
      { start: "06:00:00", end: "09:59:59", state: "force-charge", id: "4" },
      { start: "10:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "17:59:59", state: "force-charge", id: "6" },
      { start: "18:00:00", end: "20:59:59", state: "force-discharge", id: "7" },
      { start: "21:00:00", end: "21:59:59", state: "disable", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-charge", id: "9" },
    ],
    friday: [
      { start: "00:00:00", end: "01:59:59", state: "force-charge", id: "1" },
      { start: "02:00:00", end: "04:59:59", state: "force-discharge", id: "2" },
      { start: "05:00:00", end: "05:59:59", state: "disable", id: "3" },
      { start: "06:00:00", end: "09:59:59", state: "force-charge", id: "4" },
      { start: "10:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "17:59:59", state: "force-charge", id: "6" },
      { start: "18:00:00", end: "20:59:59", state: "force-discharge", id: "7" },
      { start: "21:00:00", end: "21:59:59", state: "disable", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-charge", id: "9" },
    ],
    saturday: [
      { start: "00:00:00", end: "01:59:59", state: "force-charge", id: "1" },
      { start: "02:00:00", end: "04:59:59", state: "force-discharge", id: "2" },
      { start: "05:00:00", end: "05:59:59", state: "disable", id: "3" },
      { start: "06:00:00", end: "09:59:59", state: "force-charge", id: "4" },
      { start: "10:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "17:59:59", state: "force-charge", id: "6" },
      { start: "18:00:00", end: "20:59:59", state: "force-discharge", id: "7" },
      { start: "21:00:00", end: "21:59:59", state: "disable", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-charge", id: "9" },
    ],
    sunday: [
      { start: "00:00:00", end: "01:59:59", state: "force-charge", id: "1" },
      { start: "02:00:00", end: "04:59:59", state: "force-discharge", id: "2" },
      { start: "05:00:00", end: "05:59:59", state: "disable", id: "3" },
      { start: "06:00:00", end: "09:59:59", state: "force-charge", id: "4" },
      { start: "10:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "17:59:59", state: "force-charge", id: "6" },
      { start: "18:00:00", end: "20:59:59", state: "force-discharge", id: "7" },
      { start: "21:00:00", end: "21:59:59", state: "disable", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-charge", id: "9" },
    ],
  },
  "soak-discharge-first": {
    monday: [
      { start: "00:00:00", end: "00:59:59", state: "force-discharge", id: "1" },
      { start: "01:00:00", end: "01:59:59", state: "disable", id: "2" },
      { start: "02:00:00", end: "05:59:59", state: "force-charge", id: "3" },
      { start: "06:00:00", end: "08:59:59", state: "force-discharge", id: "4" },
      { start: "09:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "16:59:59", state: "force-discharge", id: "6" },
      { start: "17:00:00", end: "17:59:59", state: "disable", id: "7" },
      { start: "18:00:00", end: "21:59:59", state: "force-charge", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-discharge", id: "9" },
    ],
    tuesday: [
      { start: "00:00:00", end: "00:59:59", state: "force-discharge", id: "1" },
      { start: "01:00:00", end: "01:59:59", state: "disable", id: "2" },
      { start: "02:00:00", end: "05:59:59", state: "force-charge", id: "3" },
      { start: "06:00:00", end: "08:59:59", state: "force-discharge", id: "4" },
      { start: "09:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "16:59:59", state: "force-discharge", id: "6" },
      { start: "17:00:00", end: "17:59:59", state: "disable", id: "7" },
      { start: "18:00:00", end: "21:59:59", state: "force-charge", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-discharge", id: "9" },
    ],
    wednesday: [
      { start: "00:00:00", end: "00:59:59", state: "force-discharge", id: "1" },
      { start: "01:00:00", end: "01:59:59", state: "disable", id: "2" },
      { start: "02:00:00", end: "05:59:59", state: "force-charge", id: "3" },
      { start: "06:00:00", end: "08:59:59", state: "force-discharge", id: "4" },
      { start: "09:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "16:59:59", state: "force-discharge", id: "6" },
      { start: "17:00:00", end: "17:59:59", state: "disable", id: "7" },
      { start: "18:00:00", end: "21:59:59", state: "force-charge", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-discharge", id: "9" },
    ],
    thursday: [
      { start: "00:00:00", end: "00:59:59", state: "force-discharge", id: "1" },
      { start: "01:00:00", end: "01:59:59", state: "disable", id: "2" },
      { start: "02:00:00", end: "05:59:59", state: "force-charge", id: "3" },
      { start: "06:00:00", end: "08:59:59", state: "force-discharge", id: "4" },
      { start: "09:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "16:59:59", state: "force-discharge", id: "6" },
      { start: "17:00:00", end: "17:59:59", state: "disable", id: "7" },
      { start: "18:00:00", end: "21:59:59", state: "force-charge", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-discharge", id: "9" },
    ],
    friday: [
      { start: "00:00:00", end: "00:59:59", state: "force-discharge", id: "1" },
      { start: "01:00:00", end: "01:59:59", state: "disable", id: "2" },
      { start: "02:00:00", end: "05:59:59", state: "force-charge", id: "3" },
      { start: "06:00:00", end: "08:59:59", state: "force-discharge", id: "4" },
      { start: "09:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "16:59:59", state: "force-discharge", id: "6" },
      { start: "17:00:00", end: "17:59:59", state: "disable", id: "7" },
      { start: "18:00:00", end: "21:59:59", state: "force-charge", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-discharge", id: "9" },
    ],
    saturday: [
      { start: "00:00:00", end: "00:59:59", state: "force-discharge", id: "1" },
      { start: "01:00:00", end: "01:59:59", state: "disable", id: "2" },
      { start: "02:00:00", end: "05:59:59", state: "force-charge", id: "3" },
      { start: "06:00:00", end: "08:59:59", state: "force-discharge", id: "4" },
      { start: "09:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "16:59:59", state: "force-discharge", id: "6" },
      { start: "17:00:00", end: "17:59:59", state: "disable", id: "7" },
      { start: "18:00:00", end: "21:59:59", state: "force-charge", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-discharge", id: "9" },
    ],
    sunday: [
      { start: "00:00:00", end: "00:59:59", state: "force-discharge", id: "1" },
      { start: "01:00:00", end: "01:59:59", state: "disable", id: "2" },
      { start: "02:00:00", end: "05:59:59", state: "force-charge", id: "3" },
      { start: "06:00:00", end: "08:59:59", state: "force-discharge", id: "4" },
      { start: "09:00:00", end: "13:59:59", state: "disable", id: "5" },
      { start: "14:00:00", end: "16:59:59", state: "force-discharge", id: "6" },
      { start: "17:00:00", end: "17:59:59", state: "disable", id: "7" },
      { start: "18:00:00", end: "21:59:59", state: "force-charge", id: "8" },
      { start: "22:00:00", end: "23:59:59", state: "force-discharge", id: "9" },
    ],
  },
};
