import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { authenticator } from '../../authenticator'
import { ajax } from '../../ajax'
import { Input, Button, Message, List, Icon, Segment, Popup, Modal, Header, Image, Divider, Table } from 'semantic-ui-react'
import { findClusters } from '../../lib/groupUnitData'

import './InstallDetails.css'
import { parseModelCode, friendlyFormat, moment } from '../../common'
import { getEnglishText } from '../../dictionary'

const WIFI_HOTSPOT_GATEWAY_IP = '192.168.4.1'
const NO_LAN_IP_THRESHOLD_SECONDS = 60 * 5 // 5 minutes

function CustomName({ unitId, customName, setCustomName }) {
  const { state } = useLocation()

  const idToken = authenticator.getToken()

  const [editMode, setEditMode] = useState(false)
  const [newCustomName, setNewCustomName] = useState(customName || '')
  const [saving, setSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const inputRef = useRef(null)

  useEffect(() => {
    if (state?.renameHardwareId === unitId) {
      setEditMode(true)
      setTimeout(() => inputRef.current.focus(), 10)
    }
    return () => {}
  }, [state, unitId])

  useEffect(() => {
    setNewCustomName(customName)
    return () => {}
  }, [customName])

  function saveCustomName() {
    let buffer = newCustomName.trim()
    const regex = /^[ a-zA-Z0-9!#)(._-]+$/g

    if (!buffer.length) {
      buffer = null
    } else {
      if (!regex.test(buffer) || buffer.length > 45) {
        window.alert(getEnglishText('install-details : custom-name-invalid'))
        return
      }
    }

    ajax
      .putCustomName(unitId, idToken, buffer)
      .then((response) => {
        const { result, errorMessage } = response
        if (result === 'success') {
          setCustomName(buffer)
          setEditMode(false)
          setSaving(false)
          setErrorMessage(null)

          // update local storage
          let storedUnitData = JSON.parse(localStorage.getItem('UNIT_DATA'))
          storedUnitData = storedUnitData['units'].map((unit) => {
            if (unit.hardwareId === unitId) {
              return { ...unit, customName: buffer }
            } else {
              return unit
            }
          })
          localStorage.setItem('UNIT_DATA', JSON.stringify({ units: storedUnitData }))

          // reload window because state is not handled properly and the Unit Selector will not be updated with this custom name change
          window.location.reload()
        } else {
          setSaving(false)
          setErrorMessage(errorMessage)
        }
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }

  return editMode ? (
    <React.Fragment>
      <div className="custom-name edit-mode">
        <Input
          size="small"
          type="text"
          loading={saving}
          ref={inputRef}
          placeholder={getEnglishText('install-details : custom-name-placeholder')}
          value={newCustomName || ''}
          onChange={({ target }) => setNewCustomName(target.value)}
          onKeyPress={({ charCode }) => {
            if (charCode === 13) saveCustomName()
          }}
          action
        >
          <input />
          <Button loading={saving} positive onClick={() => saveCustomName()} icon="check" />

          <Button
            loading={saving}
            onClick={() => {
              setEditMode(false)
              setSaving(false)
              setErrorMessage(null)
            }}
            icon="close"
          />
        </Input>
        <br />
        {errorMessage && <Message error>{errorMessage}</Message>}
        <br />
      </div>
    </React.Fragment>
  ) : (
    <span className="custom-name">
      <Icon name="tag" color="teal" />
      <a
        onClick={() => {
          setEditMode(true)
          setTimeout(() => inputRef.current.focus(), 10)
        }}
      >
        <strong>
          {customName ? (
            `"${customName}"`
          ) : (
            <span style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}>{getEnglishText('install-details : set-a-custom-name')}</span>
          )}
        </strong>
      </a>
    </span>
  )
}
function RevealPassword({ password, visible }) {
  const [reveal, setReveal] = useState(false)

  useEffect(() => {
    if (visible) setReveal(false)
  }, [visible])

  return (
    <span onClick={() => setReveal(!reveal)} className={`hotspot-key-secret ${reveal ? 'reveal' : ''}`}>
      {reveal ? password : password.replace(/./g, '●')}
    </span>
  )
}

function InstallDetails({ units, visible, user }) {
  let fetchWiFiTimeoutHandle = null
  let fetchLanIpTimeoutHandle = null
  const fetchInterval = 10000

  const [customName, setCustomName] = useState(units[0] ? units[0].customName : '')

  const [clusters, setClusters] = useState([])

  const [wifiData, setWifiData] = useState(null)
  const [lanIpData, setLanIpData] = useState(null)
  const [wlan1IpData, setWlan1IpData] = useState(null)

  const [launchIp, setLaunchIp] = useState(null)
  const [showConsoleLaunchModal, setShowConsoleLaunchModal] = useState(false) // { unit } || false
  const [showHotspotSetupModal, setShowHotspotSetupModal] = useState(false) // { unit } || false
  const [showConsoleCredsModal, setShowConsoleCredsModal] = useState(false) // { unit } || false

  useEffect(() => {
    return () => {
      clearTimeout(fetchWiFiTimeoutHandle)
      clearTimeout(fetchLanIpTimeoutHandle)
    }
  }, [])

  useEffect(() => {
    setCustomName(units[0] ? units[0].customName : '')

    if (units) setClusters(findClusters(units.map((u) => ({ unit: u }))))
    else setClusters([])

    return () => {
      clearTimeout(fetchWiFiTimeoutHandle)
      clearTimeout(fetchLanIpTimeoutHandle)
    }
  }, [units])

  useEffect(() => {
    if (visible) {
      getWifiData()
      getLanIp()
    }
    return () => {
      clearTimeout(fetchWiFiTimeoutHandle)
      clearTimeout(fetchLanIpTimeoutHandle)
    }
  }, [visible])

  async function getWifiData() {
    clearTimeout(fetchWiFiTimeoutHandle)

    let _wifiData = {}

    // fetches wifi strength data for each unit
    for (const u of units) {
      const response = await ajax.fetchLastMeasurement({ hardwareId: u.hardwareId, measurementName: 'wifiSignalStrength' })
      _wifiData = { ..._wifiData, [u.hardwareId]: { ...response?.lastData } }
    }

    setWifiData(_wifiData)

    fetchWiFiTimeoutHandle = setTimeout(getWifiData, fetchInterval)
  }

  async function getLanIp() {
    clearTimeout(fetchLanIpTimeoutHandle)

    let _lanIpData = {}
    let _wlan1IpData = {}

    // fetches wifi strength data for each unit
    for (const u of units) {
      const lapIpresponse = await ajax.fetchLastMeasurement({ hardwareId: u.hardwareId, measurementName: 'lanIp' })
      _lanIpData = { ..._lanIpData, [u.hardwareId]: { ...lapIpresponse?.lastData } }

      const wlan1Ipresponse = await ajax.fetchLastMeasurement({ hardwareId: u.hardwareId, measurementName: 'wlan1Ip' })
      _wlan1IpData = { ..._wlan1IpData, [u.hardwareId]: { ...wlan1Ipresponse?.lastData } }
    }

    setLanIpData(_lanIpData)
    setWlan1IpData(_wlan1IpData)

    fetchLanIpTimeoutHandle = setTimeout(getLanIp, fetchInterval)
  }

  function cleanFirmwareName(firmwareVersion) {
    let buffer = firmwareVersion
    buffer = buffer.replace('-emergency', '')
    buffer = buffer.replace('emergency', '')
    buffer = buffer.replace('-issue', '')
    buffer = buffer.replace('issue', '')
    return buffer
  }

  function WifiIcon({ hardwareId }) {
    let color = 'red'

    try {
      let { wifiSignalStrength } = wifiData[hardwareId]
      wifiSignalStrength = parseInt(wifiSignalStrength[1], 10)

      if (wifiSignalStrength > 30) color = 'orange'
      if (wifiSignalStrength > 60) color = 'green'

      return (
        <Popup
          content={`WiFi signal strength: ${wifiSignalStrength}%`}
          trigger={
            <div className="wifi-strength-icon">
              <Icon name="wifi" color={color} />
              <small style={{ opacity: 0.7 }}>{wifiSignalStrength}%</small>
            </div>
          }
        />
      )
    } catch (error) {
      return null
    }
  }

  function shouldShowGoToConsole(unit) {
    return parseModelCode(unit.model).hasWifiHotspot === true
  }

  function goToConsole(unit) {
    const { hardwareId, hotspotKey, ownerEmail } = unit

    function isIPv4Address(str) {
      const ipv4Regex =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
      return ipv4Regex.test(str)
    }

    if (!lanIpData[hardwareId]?.lanIp && !wlan1IpData[hardwareId]?.wlan1Ip) {
      //////////////////////
      // LAN DISCONNECTED //
      //////////////////////

      // lanIp data couldn't be found for this unit, assume that network is disconnected and display optional hotspot instructions
      setLaunchIp(WIFI_HOTSPOT_GATEWAY_IP)
      setShowHotspotSetupModal({ unit })
    } else {
      let targetIp = null
      let secondsSinceTargetIp = null

      try {
        const [lanIpDate, lanIp] = lanIpData[hardwareId].lanIp
        const [wlan1IpDate, wlan1Ip] = wlan1IpData[hardwareId].wlan1Ip

        if (isIPv4Address(wlan1Ip)) {
          targetIp = wlan1Ip // unit is connected to LAN via wlan1 (wireless)
          secondsSinceTargetIp = moment().diff(moment(wlan1IpDate), 'seconds')
        } else if (isIPv4Address(lanIp)) {
          targetIp = lanIp // unit is connected to LAN via eth0 (ethernet)
          secondsSinceTargetIp = moment().diff(moment(lanIpDate), 'seconds')
        } else {
          targetIp = null // unit has no known connection to LAN, assume customer needs to run wifi wizard on Powervault Console
        }
      } catch (error) {}

      if (!targetIp || secondsSinceTargetIp > NO_LAN_IP_THRESHOLD_SECONDS) {
        //////////////////////
        // LAN DISCONNECTED //
        //////////////////////

        // targetIp is too old, assume that network is disconnected and display optional hotspot instructions
        setLaunchIp(WIFI_HOTSPOT_GATEWAY_IP)
        setShowHotspotSetupModal({ unit })
      } else {
        ///////////////////
        // LAN CONNECTED //
        ///////////////////

        // lanIp is fresh, open Powervault Console in new tab
        setLaunchIp(targetIp)
        setShowConsoleLaunchModal({ unit })
      }
    }
  }

  return (
    <div id="InstallDetails">
      {Array.isArray(units) && units.length > 0 ? (
        units.length === 1 ? (
          /////////////////
          // SINGLE UNIT //
          /////////////////
          <Segment padded style={{ marginLeft: '2em', marginRight: '2em' }}>
            <List>
              <List.Item>
                <List.Content>
                  <CustomName unitId={units[0].unitId} customName={customName} setCustomName={setCustomName} />
                  {parseModelCode(units[0].model).batteryCapacity && (
                    <span>
                      {getEnglishText('install-details : label-battery-capacity')}:{' '}
                      {friendlyFormat('batteryCapacity', parseModelCode(units[0].model).batteryCapacity).join(' ')}
                    </span>
                  )}
                  <WifiIcon hardwareId={units[0].hardwareId} />
                  {/* {batteryType && <span>Battery Type: {batteryType}</span>} */}
                  {units[0].firmwareVersion && (
                    <span className="firmware">
                      {getEnglishText('install-details : label-firmware')}:&nbsp;&nbsp;{cleanFirmwareName(units[0].firmwareVersion)}
                    </span>
                  )}
                  {units[0].unitId && (
                    <span className="unit-id">
                      {getEnglishText('install-details : label-id')}:&nbsp;&nbsp;{units[0].unitId}
                    </span>
                  )}
                  {units[0].model && (
                    <span className="model">
                      {getEnglishText('install-details : label-modal-code')}:&nbsp;&nbsp;{units[0].model}
                    </span>
                  )}
                  {units[0].configId && (
                    <span className="contract-id">
                      {getEnglishText('install-details : label-contract-id')}:&nbsp;&nbsp;{units[0].configId}
                    </span>
                  )}
                  {units[0].hotspotKey && parseModelCode(units[0].model).hasWifiHotspot === true && (
                    <span className="hotspot-key">
                      {getEnglishText('install-details : label-hotspot-key')}:&nbsp;&nbsp;
                      <RevealPassword visible={visible} password={units[0].hotspotKey} />
                    </span>
                  )}
                </List.Content>
              </List.Item>
            </List>

            {/* ///////////// */}
            {/* GO TO CONSOLE */}
            {/* ///////////// */}

            {shouldShowGoToConsole(units[0]) && (
              <>
                <Button secondary basic size="tiny" content="Configure WiFi" floated="right" onClick={() => goToConsole(units[0])} />
                <div style={{ clear: 'both' }}></div>
              </>
            )}
          </Segment>
        ) : (
          ////////////////////
          // MULTIPLE UNITS //
          ////////////////////
          <Segment.Group style={{ marginLeft: '2em', marginRight: '2em' }}>
            {units.map((unit, index) => {
              const { unitId, firmwareVersion, model, configId, customName, hotspotKey, dateWarrantyActivated } = unit
              const { productName, batteryType, batteryCapacity } = parseModelCode(model)

              return (
                <Segment padded key={index}>
                  <List>
                    <List.Item>
                      <List.Content>
                        {customName && (
                          <span className="custom-name">
                            <Icon name="tag" color="teal" />
                            <strong>"{customName}"</strong>
                          </span>
                        )}
                        <WifiIcon hardwareId={units[0].hardwareId} />
                        {batteryCapacity && (
                          <span>
                            {getEnglishText('install-details : label-battery-capacity')}: {friendlyFormat('batteryCapacity', batteryCapacity).join(' ')}
                          </span>
                        )}
                        {/* {batteryType && <span>Battery Type: {batteryType}</span>} */}
                        {firmwareVersion && (
                          <span className="firmware">
                            {getEnglishText('install-details : label-firmware')}:&nbsp;&nbsp;{cleanFirmwareName(firmwareVersion)}
                          </span>
                        )}
                        {unitId && (
                          <span className="unit-id">
                            {getEnglishText('install-details : label-id')}:&nbsp;&nbsp;{unitId}
                          </span>
                        )}
                        {model && (
                          <span className="model">
                            {getEnglishText('install-details : label-modal-code')}:&nbsp;&nbsp;{model}
                          </span>
                        )}
                        {configId && (
                          <span className="contract-id">
                            {getEnglishText('install-details : label-contract-id')}:&nbsp;&nbsp;{configId}
                          </span>
                        )}
                        {hotspotKey && parseModelCode(model).hasWifiHotspot === true && (
                          <span className="hotspot-key">
                            {getEnglishText('install-details : label-hotspot-key')}:&nbsp;&nbsp;
                            <RevealPassword password={hotspotKey} />
                          </span>
                        )}
                      </List.Content>
                    </List.Item>
                  </List>

                  {/* ///////////// */}
                  {/* GO TO CONSOLE */}
                  {/* ///////////// */}

                  {shouldShowGoToConsole(unit) && (
                    <>
                      <Button secondary basic size="tiny" content="Configure WiFi" floated="right" onClick={() => goToConsole(unit)} />
                      <div style={{ clear: 'both' }}></div>
                    </>
                  )}
                </Segment>
              )
            })}
          </Segment.Group>
        )
      ) : (
        <Message info>
          <Message.Header>{getEnglishText('install-details : nothing-selected-title')}</Message.Header>
          {getEnglishText('install-details : nothing-selected-body')}
        </Message>
      )}

      {/************************/}
      {/* CONSOLE LAUNCH MODAL */}
      {/************************/}

      {showConsoleLaunchModal?.unit && (
        <>
          <Modal
            open
            dimmer="blurring"
            onClose={(e) => {
              e.stopPropagation()
            }}
          >
            <Modal.Header>Are you connected to the same network as your Powervault?</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <p>To access your Powervault's WiFi settings you must be connected to the same network as your Powervault - your home network for example.</p>
                <p>Ensure you are connected to the same network before continuing.</p>
                <p>
                  <strong>It is best that you use a phone, tablet or laptop to do this, not a desktop.</strong>
                </p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button secondary color="black" onClick={() => setShowConsoleLaunchModal(false)}>
                Cancel
              </Button>
              <Button
                primary
                content="Continue"
                labelPosition="right"
                icon="chevron right"
                onClick={() => {
                  setShowConsoleLaunchModal(false)
                  if (showConsoleLaunchModal?.unit) {
                    setShowConsoleCredsModal({ unit: showConsoleLaunchModal.unit })
                  }
                }}
                positive
              />
            </Modal.Actions>
          </Modal>
        </>
      )}

      {/*****************/}
      {/* HOTSPOT MODAL */}
      {/*****************/}

      {showHotspotSetupModal?.unit && (
        <>
          <Modal
            open
            dimmer="blurring"
            onClose={(e) => {
              e.stopPropagation()
            }}
            size="large"
          >
            <Modal.Header>Configure WiFi</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Header style={{ marginTop: '1rem' }} color="red">
                  <Icon name="exclamation" fitted style={{ paddingRight: '0.5em' }} />
                  Your Powervault is experiencing intermittent connection issues
                </Header>

                <Divider hidden />

                <Header>Does your Powervault use a wired connection?</Header>
                <p>
                  If your Powervault uses a wired connection, please wait a while and try again later. Your internet connection might be having temporary
                  issues.
                </p>
                <Button secondary content="Try again later" style={{ marginLeft: '1em' }} onClick={() => setShowHotspotSetupModal(false)} />

                <Divider hidden />

                <Header>Does your Powervault use a wireless connection?</Header>
                <p>
                  If your Powervault uses a wireless connection you may want to connect it to a different wireless network - if your WiFi password has changed
                  for example.
                </p>

                <p>
                  You can configure the wireless connection via your Powervault's built-in WiFi hotspot. It is best that you use a phone, tablet or laptop to do
                  this, not a desktop.
                </p>

                <p>
                  Move close to your Powervault and then, <strong>on this device</strong> search for and connect to the following WiFi hotspot:
                </p>

                <Divider hidden />

                <Table celled collapsing style={{ marginLeft: '1em' }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell textAlign="right">Network Name:</Table.Cell>
                      <Table.Cell>
                        <strong>
                          <tt>powervault-{showHotspotSetupModal?.unit?.hardwareId}</tt>
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="right">Network Password:</Table.Cell>
                      <Table.Cell>
                        <strong style={{ color: '#c1272d' }}>
                          <tt>{showHotspotSetupModal?.unit?.hotspotKey}</tt>
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Divider hidden />

                <p>Once this device is connected to your Powervault's WiFi hotspot, click below.</p>

                <Divider hidden />

                <Button
                  style={{ marginLeft: '1em' }}
                  primary
                  content="Continue"
                  labelPosition="right"
                  icon="chevron right"
                  onClick={() => {
                    setShowHotspotSetupModal(false)
                    if (showHotspotSetupModal?.unit) {
                      setShowConsoleCredsModal({ unit: showHotspotSetupModal.unit })
                    }
                  }}
                  positive
                />

                <Divider hidden />
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button basic secondary color="black" onClick={() => setShowHotspotSetupModal(false)}>
                Cancel
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )}

      {/***********************/}
      {/* CONSOLE CREDS MODAL */}
      {/***********************/}

      {showConsoleCredsModal?.unit && (
        <>
          <Modal
            open
            dimmer="blurring"
            onClose={(e) => {
              e.stopPropagation()
            }}
            size="small"
          >
            <Modal.Header>Powervault Console Login</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Header style={{ marginTop: '1rem' }}>You will need these login details to access your Powervault's Console:</Header>

                <Divider hidden />

                <Table celled collapsing style={{ marginLeft: '1em' }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell textAlign="right">Email:</Table.Cell>
                      <Table.Cell>
                        <strong>
                          <tt>{showConsoleCredsModal?.unit?.ownerEmail ? showConsoleCredsModal?.unit?.ownerEmail : 'Unknown email'}</tt>
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="right">Passcode:</Table.Cell>
                      <Table.Cell>
                        <strong style={{ color: 'red' }}>
                          <tt>{showConsoleCredsModal?.unit?.hotspotKey}</tt>
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Divider hidden />

                <p>Please take note of these details, you will need them after clicking Continue.</p>

                <Divider hidden />
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button secondary color="black" onClick={() => setShowConsoleCredsModal(false)}>
                Cancel
              </Button>

              <Button
                style={{ marginLeft: '1em' }}
                primary
                content="Launch Console"
                labelPosition="right"
                icon="external"
                onClick={() => {
                  setShowConsoleCredsModal(false)

                  /////////////////////////////
                  // OPEN CONSOLE IN NEW TAB //
                  /////////////////////////////
                  let qs = ''
                  if (showConsoleCredsModal?.unit?.ownerEmail) qs += `?email=${showConsoleCredsModal.unit.ownerEmail}`

                  const newWindow = window.open(`http://${launchIp}${qs}`, '_blank', 'noopener,noreferrer')
                  if (newWindow) newWindow.opener = null
                }}
                positive
              />
            </Modal.Actions>
          </Modal>
        </>
      )}
    </div>
  )
}

export { InstallDetails }
