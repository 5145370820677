import React, { useEffect, useState } from 'react'
import { Grid, Button, Header, Icon, Segment, Message, Divider, Modal } from 'semantic-ui-react'
import { authenticator } from '../../authenticator'
import { ajax } from '../../ajax'
import { parseFirmwareString, parseModelCode } from '../../common'

import { getEnglishText } from '../../dictionary'

import './LEDSettings.css'

function LEDSettings(props) {
  const idToken = authenticator.getToken()
  const { unitDetails, onClose } = props
  const { hardwareId, firmwareVersion, model } = unitDetails
  const [parsedModelCode, setParsedModelCode] = useState(parseModelCode(model))

  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [saveFailed, setSaveFailed] = useState(false)

  const [ledData, setLedData] = useState({})

  const [mainModelOpen, setMainModelOpen] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)

  const { rainbowLedModeAvailable } = parseFirmwareString(firmwareVersion)

  useEffect(() => {
    ajax
      .fetchRaw(idToken, `config?epromId=${hardwareId}`)
      .then((data) => {
        setLedData(data.pvlite_led)
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }, [hardwareId])

  function putLEDSettings(enabled) {
    setSaving(true)

    const data = { pvlite_led: ledData }

    ajax
      .putRaw(idToken, `config?epromId=${hardwareId}`, data)
      .then((response) => {
        const { result, errorMessage } = response
        if (result === 'success') {
          setSaved(true)
          setSaving(false)
          setErrorMessage(null)
          setSaveFailed(false)
        } else {
          setSaveFailed(true)
          setSaving(false)
          setErrorMessage(errorMessage)
        }
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }

  function LEDOption({ name, value, color, rainbow = false }) {
    const { pattern } = ledData || {}

    const style = rainbow
      ? { background: 'linear-gradient(90deg, rgba(236,159,154,1) 0%, rgba(207,241,126,1) 48%, rgba(0,212,255,1) 100%)' }
      : { backgroundColor: color }

    return (
      <Segment
        style={style}
        className={`option ${pattern === value ? 'active' : null}`}
        onClick={() => {
          if (!saving) setLedData({ ...ledData, pattern: value })
        }}
      >
        <strong>{name}</strong>
        <Icon name="check" size="large" />
      </Segment>
    )
  }

  return (
    <div className="led-settings">
      {/* main modal */}
      <Modal id="led-settings-modal" open={mainModelOpen} size="small" centered={false} closeOnEscape={false} closeOnDimmerClick={false} dimmer="blurring">
        <Modal.Header className="control-bar">
          <Header as="h2">
            <Header.Content>{getEnglishText('led-settings : modal-title')}</Header.Content>
          </Header>
        </Modal.Header>

        {ledData !== null && (
          <React.Fragment>
            <Modal.Content>
              <Modal.Description>
                {saveFailed ? (
                  ////////////////////
                  // FAILED TO SAVE //
                  ////////////////////
                  <Header icon textAlign="center">
                    <Icon name="exclamation" />
                    <br />
                    {getEnglishText('led-settings : save-failed-title')}
                    <Header.Subheader>
                      <>{getEnglishText('led-settings : save-failed-body')}</>
                    </Header.Subheader>
                  </Header>
                ) : saved ? (
                  //////////////////
                  // SAVE SUCCESS //
                  //////////////////
                  <Header icon textAlign="center">
                    <Icon name="check" />
                    <br />
                    {getEnglishText('led-settings : settings-saved')}
                  </Header>
                ) : (
                  <>
                    <p>{getEnglishText('led-settings : select-an-animation')}</p>

                    <Divider hidden />

                    <Grid columns={parsedModelCode.productLineStaff === 'P5' ? 3 : 4} stackable>
                      <Grid.Row>
                        <Grid.Column>
                          {parsedModelCode.productLineStaff === 'P5' ? (
                            <LEDOption name="Discrete LED mode" value={1} />
                          ) : (
                            <LEDOption name={getEnglishText('led-settings : bright-fast-animation')} value={1} />
                          )}
                        </Grid.Column>
                        <Grid.Column>
                          {parsedModelCode.productLineStaff === 'P5' ? (
                            <LEDOption name="Normal LED mode" value={2} />
                          ) : (
                            <LEDOption name={getEnglishText('led-settings : bright-medium-animation')} value={2} />
                          )}
                        </Grid.Column>

                        {parsedModelCode.productLineStaff !== 'P5' && (
                          <>
                            <Grid.Column>
                              <LEDOption name={getEnglishText('led-settings : bright-slow-animation')} value={3} />
                            </Grid.Column>
                            <Grid.Column>
                              <LEDOption name={getEnglishText('led-settings : bright-static-display')} value={4} />
                            </Grid.Column>
                          </>
                        )}
                      </Grid.Row>
                      {parsedModelCode.productLineStaff !== 'P5' && (
                        <>
                          <Grid.Row>
                            <Grid.Column>
                              <LEDOption name={getEnglishText('led-settings : dark-fast-animation')} color="#ddd" value={5} />
                            </Grid.Column>
                            <Grid.Column>
                              <LEDOption name={getEnglishText('led-settings : dark-medium-animation')} color="#ddd" value={6} />
                            </Grid.Column>
                            <Grid.Column>
                              <LEDOption name={getEnglishText('led-settings : dark-slow-animation')} color="#ddd" value={7} />
                            </Grid.Column>
                            <Grid.Column>
                              <LEDOption name={getEnglishText('led-settings : dark-static-display')} color="#ddd" value={8} />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column>
                              <LEDOption name={getEnglishText('led-settings : disabled')} color="#aaa" value={0} />
                            </Grid.Column>
                          </Grid.Row>
                          {rainbowLedModeAvailable === true && (
                            <Grid.Row>
                              <Grid.Column>
                                <LEDOption name={getEnglishText('led-settings : disco-mode!')} rainbow={true} value={9} />
                              </Grid.Column>
                            </Grid.Row>
                          )}
                        </>
                      )}
                    </Grid>

                    <Divider hidden />

                    {errorMessage && <Message error>{errorMessage}</Message>}
                  </>
                )}
              </Modal.Description>
            </Modal.Content>

            <Modal.Actions>
              <Button
                primary={saved}
                secondary={!saved}
                disabled={saving}
                onClick={() => {
                  setMainModelOpen(false)
                  if (onClose) onClose()
                }}
              >
                {getEnglishText('common : close')}
              </Button>
              {!saveFailed && !saved && (
                <Button
                  primary={!saved}
                  disabled={saving}
                  loading={saving}
                  onClick={() => {
                    putLEDSettings(false)
                  }}
                >
                  {getEnglishText('common : save-settings')}
                </Button>
              )}
            </Modal.Actions>
          </React.Fragment>
        )}
      </Modal>
    </div>
  )
}

export { LEDSettings }
