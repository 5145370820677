import React, { useState } from 'react'
import { Button, Header } from 'semantic-ui-react'
import { getEnglishText } from '../../dictionary'

import './SendToMobile.css'

function SendToMobile() {
  const [showPopup, setShowPopup] = useState(!isDismissed() && !runningAsApp())

  function isDismissed() {
    return localStorage.getItem('sendToMobileDismissed') ? true : false
  }

  function dismissMessage() {
    localStorage.setItem('sendToMobileDismissed', 'true')
    setShowPopup(false)
  }

  function runningAsApp() {
    try {
      const url = window.location.toString()
      return url.includes('mode=app')
    } catch (e) {
      return false
    }
  }

  if (showPopup) {
    return (
      <div id="SendToMobile">
        {/* Desktop QR Code */}

        <div className="desktop-popup mobile-hidden">
          <Header as="h2">{getEnglishText('send-to-mobile : desktop-title')}</Header>
          <p>{getEnglishText('send-to-mobile : desktop-description')}</p>
          <img width="100%" src="static/images/qr-code.png" alt="QR Code" />
          <br />
          <Button primary content={getEnglishText('send-to-mobile : not-now')} onClick={() => setShowPopup(false)} />
          &nbsp;&nbsp;
          <Button secondary content={getEnglishText('send-to-mobile : dont-ask-me-again')} onClick={dismissMessage} />
        </div>

        {/* Mobile App Instructions */}

        <div className="mobile-popup mobile-only">
          <Header as="h2">{getEnglishText('send-to-mobile : mobile-title')}</Header>
          {getEnglishText('send-to-mobile : mobile-description')}
          <br />
          <Button primary content={getEnglishText('send-to-mobile : not-now')} onClick={() => setShowPopup(false)} />
          &nbsp;&nbsp;
          <Button secondary content={getEnglishText('send-to-mobile : dont-ask-me-again')} onClick={dismissMessage} />
        </div>
      </div>
    )
  } else {
    return null
  }
}

export { SendToMobile }
