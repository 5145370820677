module.exports = {
  "soak-charge-first": {
    monday: [
      { start: "00:00:00", end: "01:59:59", state: "on", id: "0" },
      { start: "06:00:00", end: "09:59:59", state: "on", id: "2" },
      { start: "14:00:00", end: "17:59:59", state: "on", id: "3" },
      { start: "22:00:00", end: "23:59:59", state: "on", id: "5" }
    ],
    tuesday: [
      { start: "00:00:00", end: "01:59:59", state: "on", id: "0" },
      { start: "06:00:00", end: "09:59:59", state: "on", id: "2" },
      { start: "14:00:00", end: "17:59:59", state: "on", id: "3" },
      { start: "22:00:00", end: "23:59:59", state: "on", id: "5" }
    ],
    wednesday: [
      { start: "00:00:00", end: "01:59:59", state: "on", id: "0" },
      { start: "06:00:00", end: "09:59:59", state: "on", id: "2" },
      { start: "14:00:00", end: "17:59:59", state: "on", id: "3" },
      { start: "22:00:00", end: "23:59:59", state: "on", id: "5" }
    ],
    thursday: [
      { start: "00:00:00", end: "01:59:59", state: "on", id: "0" },
      { start: "06:00:00", end: "09:59:59", state: "on", id: "2" },
      { start: "14:00:00", end: "17:59:59", state: "on", id: "3" },
      { start: "22:00:00", end: "23:59:59", state: "on", id: "5" }
    ],
    friday: [
      { start: "00:00:00", end: "01:59:59", state: "on", id: "0" },
      { start: "06:00:00", end: "09:59:59", state: "on", id: "2" },
      { start: "14:00:00", end: "17:59:59", state: "on", id: "3" },
      { start: "22:00:00", end: "23:59:59", state: "on", id: "5" }
    ],
    saturday: [
      { start: "00:00:00", end: "01:59:59", state: "on", id: "0" },
      { start: "06:00:00", end: "09:59:59", state: "on", id: "2" },
      { start: "14:00:00", end: "17:59:59", state: "on", id: "3" },
      { start: "22:00:00", end: "23:59:59", state: "on", id: "5" }
    ],
    sunday: [
      { start: "00:00:00", end: "01:59:59", state: "on", id: "0" },
      { start: "06:00:00", end: "09:59:59", state: "on", id: "2" },
      { start: "14:00:00", end: "17:59:59", state: "on", id: "3" },
      { start: "22:00:00", end: "23:59:59", state: "on", id: "5" }
    ]
  },
  "soak-discharge-first": {
    monday: [
      { start: "02:00:00", end: "05:59:59", state: "on", id: "1" },
      { start: "18:00:00", end: "21:59:59", state: "on", id: "4" },
    ],
    tuesday: [
      { start: "02:00:00", end: "05:59:59", state: "on", id: "1" },
      { start: "18:00:00", end: "21:59:59", state: "on", id: "4" },
    ],
    wednesday: [
      { start: "02:00:00", end: "05:59:59", state: "on", id: "1" },
      { start: "18:00:00", end: "21:59:59", state: "on", id: "4" },
    ],
    thursday: [
      { start: "02:00:00", end: "05:59:59", state: "on", id: "1" },
      { start: "18:00:00", end: "21:59:59", state: "on", id: "4" },
    ],
    friday: [
      { start: "02:00:00", end: "05:59:59", state: "on", id: "1" },
      { start: "18:00:00", end: "21:59:59", state: "on", id: "4" },
    ],
    saturday: [
      { start: "02:00:00", end: "05:59:59", state: "on", id: "1" },
      { start: "18:00:00", end: "21:59:59", state: "on", id: "4" },
    ],
    sunday: [
      { start: "02:00:00", end: "05:59:59", state: "on", id: "1" },
      { start: "18:00:00", end: "21:59:59", state: "on", id: "4" },
    ]
  }
};
