import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

function ExternalLink({
  to = null,
  href = null,
  content = null,
  target = '_blank',
  rel = 'noreferrer',
  referrerPolicy = 'no-referrer',
  className = '',
  onClick = null,
  iconName = null,
  iconSize = null,
  children,
  state = null
}) {
  return (
    <Link
      className={className}
      to={to || href}
      target={target}
      rel={rel}
      referrerPolicy={referrerPolicy}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      state={state}
    >
      {iconName && <Icon name={iconName} size={iconSize} />}
      {content}
      {children}
    </Link>
  )
}

function InternalLink({ to = null, content = null, className = '', onClick = null, iconName = null, iconSize = null, children, state = null }) {
  return (
    <ExternalLink
      to={to}
      content={content}
      className={className}
      onClick={onClick}
      iconName={iconName}
      iconSize={iconSize}
      children={children}
      target={null}
      state={state}
    />
  )
}

export default ExternalLink
export { ExternalLink, InternalLink }
