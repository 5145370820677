import { debugMessage } from '../common.js'

const REACT_APP_CLONED_DATA_MODE = process.env.REACT_APP_CLONED_DATA_MODE === 'true'

var lastMasterId = null;

function aggregateMeasurement({ measurementName, measurements, isCluster, unitsInClusterCount, cluster_i }) {
  // measurements = [[t, 1], [t, 2], [t, 3], ...]
  // measurements = [[t, 1], [t, 2], [null, null], ...]

  const isString = (value) => typeof value === 'string' || value instanceof String
  const isNumber = (n) => (Number(n) === n && n % 1 === 0) || (Number(n) === n && n % 1 !== 0) // is integer or float

  // debugger

  const masterMeasurements = (arr, mid) => {
    if (mid === null){
      return averageMeasurements(arr)
    }
    const timestamp = arr[0][0]
    if (arr.length > mid) {
      // debugger
      return [timestamp, arr[mid][1]]
    }
    // debugger
    return averageMeasurements(arr)
  }

  const averageMeasurements = (arr) => {
    const timestamp = arr[0][0]
    let sum = 0
    for (let i in arr) sum += arr[i][1]
    const average = sum / arr.length
    return [timestamp, average]
  }

  const sumMeasurements = (arr) => {
    const timestamp = arr[0][0]
    let sum = 0
    for (let i in arr) sum += arr[i][1]
    return [timestamp, sum]
  }

  const sumMeasurementsAndDivideBecauseOfClonedData = (arr) => {
    const timestamp = arr[0][0]
    let sum = 0
    for (let i in arr) sum += arr[i][1]
    return [timestamp, sum / unitsInClusterCount]
  }

  const maxMeasurements = (arr) => {
    const timestamp = arr[0][0]
    let max = arr[0][1]
    for (let i in arr) if (arr[i][1] > max) max = arr[i][1]
    return [timestamp, max]
  }

  // remove non numbers
  const usableMeasurements = measurements.filter((m) => isString(m[0]) && isNumber(m[1]))

  // filter useable measurements
  if (usableMeasurements.length === 0) return measurements[0] // if no useable measurements then return the first one (probably [null, null])

  switch (measurementName) {
    case 'INV_BATTERY_DcCapacity_INST_pct_5minMEAN':
    case 'SafetyCheck_UsableStateOfCharge_INST_pct_5minMEAN':
    case 'M4_undefined_StateOfCharge_INST_pct_5minMEAN':
    case 'Pylontech_undefined_StateOfCharge_INST_pct_5minMEAN':
      // always average
      return averageMeasurements(measurements)
    case 'FFR_AUX1_ActivePower_INST_mW_5minMEAN':
    case 'FFR_HOUSE_ActivePower_INST_mW_5minMEAN':
    case 'INV_PV1_ActivePower_INST_mW_5minMEAN':
    case 'INV_PV2_ActivePower_INST_mW_5minMEAN':
    case 'HealthCheck_Network_Ping_Google__5minMEAN':
    case 'FFR_FAULT_HOUSECLAMPDISCONNECTED_INST_NONE':
    case 'INV_LOGIC_DNOACTIVATED_INST_NONE':
    case 'SDM_HOUSE_ActivePower_INST_mW':
    case 'INV_GRID_ApparentPower_INST_mVA_5minMEAN':
      if (isCluster) {
        // first
        // return averageMeasurements(measurements)
        return masterMeasurements(measurements, cluster_i)
      } else {
        // sum
        return sumMeasurements(measurements)
      }
    case 'EPS_EPS_GRIDLOSS_INST_NONE':
    case 'EPS_EPS_GRIDLOSSLOWSOC_INST_NONE':
    case 'HealthCheck_Network_Ping_Google_%_5minMEAN':
    case 'EPS_EPS_GridLossOverConsumption_INST_NONE':
      // max
      return maxMeasurements(measurements)
    case 'FFR_LOCAL_ActivePower_INST_mW_5minMEAN':
      if (REACT_APP_CLONED_DATA_MODE === true) return sumMeasurementsAndDivideBecauseOfClonedData(measurements)

    case 'Pack0_DcCurrent_INST_mA_5minMEAN':
    case 'Pack1_DcCurrent_INST_mA_5minMEAN':
    case 'Pack2_DcCurrent_INST_mA_5minMEAN':
    case 'Pack3_DcCurrent_INST_mA_5minMEAN':
    case 'Pack4_DcCurrent_INST_mA_5minMEAN':
    case 'Pack5_DcCurrent_INST_mA_5minMEAN':
    case 'Pack6_DcCurrent_INST_mA_5minMEAN':
    case 'Pack7_DcCurrent_INST_mA_5minMEAN':
    case 'Pack8_DcCurrent_INST_mA_5minMEAN':
    case 'Pack9_DcCurrent_INST_mA_5minMEAN':
    case 'Pack0_DcVoltage_INST_mV_5minMEAN':
    case 'Pack1_DcVoltage_INST_mV_5minMEAN':
    case 'Pack2_DcVoltage_INST_mV_5minMEAN':
    case 'Pack3_DcVoltage_INST_mV_5minMEAN':
    case 'Pack4_DcVoltage_INST_mV_5minMEAN':
    case 'Pack5_DcVoltage_INST_mV_5minMEAN':
    case 'Pack6_DcVoltage_INST_mV_5minMEAN':
    case 'Pack7_DcVoltage_INST_mV_5minMEAN':
    case 'Pack8_DcVoltage_INST_mV_5minMEAN':
    case 'Pack9_DcVoltage_INST_mV_5minMEAN':
    default:
      // sum
      return sumMeasurements(measurements)
  }
}

function createClustersAndMergeRawData({ unitsData, isCluster }) {
  /*
    unitsData = [
      {
        unit: {
          hardwareId: '12345677',
          clusterHash: '3dd4baf3597c8f3d09a0c2d7ab9712bd' || undefined,
          ... more meta
        },
        rawData: {
          FFR_LOCAL_ActivePower_INST_mW_5minMEAN: [
            ["2023-08-09T03:40:00Z", 12],
            ... more steps
          ],
          ... more measurements
        },
        totals: {
          batteryCharge: 11768889,
          batteryDischarge: 10262769,
          gridExports: 0,
          gridImports: 35480636,
          homeConsumed: 43080214,
          solarConsumed: 15994818,
          solarGenerated: 15994818
        }
      },
      ... more units
    ]
  */

  // debug
  debugMessage(`Found ${unitsData.length} units`, 'createClustersAndMergeRawData()')

  //////////////////////
  // COLLECT CLUSTERS //
  //////////////////////

  let clusters = {}
  // collect units by clusterHash if present (otherwise use the key 'no-cluster')
  for (let i in unitsData) {
    const unitData = unitsData[i]
    const { unit } = unitData
    let { clusterHash } = unit
    if (isCluster === false || !clusterHash) clusterHash = 'no-cluster'
    if (!clusters[clusterHash]) clusters[clusterHash] = []
    clusters[clusterHash].push(unitData)
  }
  // convert `clusters` from object to array
  clusters = Object.keys(clusters).map((clusterHash) => clusters[clusterHash])

  const unitsInClusterCount = unitsData.length / clusters.length

  // debug
  debugMessage(`Found ${clusters.length} clusters`, 'createClustersAndMergeRawData()')

  ////////////////////////
  // AGGREGATE RAW DATA //
  ////////////////////////

  for (let i in clusters) {
    const cluster = clusters[i]

    // data has no `rawData` so maybe it was a `totalOnly` response
    if (!cluster[0].rawData) {
      const units = clusters[i].map((c) => c.unit)
      const { totals } = sumChartData(clusters[i], isCluster) // TODO:
      clusters[i] = {}
      clusters[i].units = units
      clusters[i].rawData = null
      clusters[i].totals = totals
      continue
    }

    const measurementNames = Object.keys(cluster[0].rawData) //                         ['FFR_LOCAL_ActivePower_INST_mW_5minMEAN', ...]
    const measurementSampleCount = cluster[0].rawData[measurementNames[0]].length //    1 - 288
    const aggregatedMeasurements = {}

    // update master if available
    for (let j = 0; j < cluster.length; j++) {
      if (cluster[j]["rawData"]["Socket_MASTER_CLUSTERUNITID_INST_NONE"][0][1]){
        lastMasterId = cluster[j]["rawData"]["Socket_MASTER_CLUSTERUNITID_INST_NONE"][0][1]
        break
      }
    }

    let cluster_i = null
    // find (e.unit.hardwareId for e in cluster).index(lastMasterId)
    // if left as null will use average
    for (let j = 0; j < cluster.length; j++) {
      if (cluster[j]["unit"]["epromId"] === lastMasterId){
        cluster_i = j
        break
      }
    }

    for (let j = 0; j < measurementSampleCount; j++) {
      measurementNames.forEach((measurementName) => {
        const measurements = cluster.map((unit) => unit.rawData[measurementName][j])
        if (!aggregatedMeasurements[measurementName]) aggregatedMeasurements[measurementName] = []

        ////////////////////////////////
        // AGGREGATE EACH MEASUREMENT //
        ////////////////////////////////

        // debug
        // if (j === 36 && ['FFR_HOUSE_ActivePower_INST_mW_5minMEAN', 'FFR_LOCAL_ActivePower_INST_mW_5minMEAN'].includes(measurementName)) {
        //   console.log('debug', j, measurementName, measurements, aggregateMeasurement({ measurementName, measurements, isCluster }))
        // }

        // if any measurements contain bad data then bypass aggregator function
        for (let k in measurements)
          if (!measurements[k]) {
            aggregatedMeasurements[measurementName].push([undefined, undefined])
            return
          }

        aggregatedMeasurements[measurementName].push(aggregateMeasurement({ measurementName, measurements, isCluster, unitsInClusterCount, cluster_i }))
      })
    }

    // rebuild clusters[i] with aggregated measurements added
    const units = clusters[i].map((c) => c.unit)
    const { totals } = sumChartData(clusters[i], true)
    clusters[i] = {}
    clusters[i].units = units
    clusters[i].rawData = aggregatedMeasurements
    clusters[i].totals = totals
  }

  ///////////////////
  // RETURN OBJECT //
  ///////////////////

  return clusters
}

function extendChartData(data) {
  // Add the following key values to every step:
  //  `isNow`         = true | false
  //  `missingData`   = true | false
  //  `noInternet`    = true | false

  const moment = require('moment')

  const { series } = data || {}

  if (!series) return data // if series key is missing just return because it's likely a request for only totals

  let pastNow = false

  const chartData = series.map((row, index) => {
    /*
      batteryInputFromGrid: null
      batteryInputFromSolar: null
      batteryOutputConsumedByHome: null
      batteryOutputExported: null
      gridConsumedByHome: null
      homeConsumed: null
      instant_battery: null
      instant_demand: null
      instant_grid: null
      instant_solar: null
      solarConsumedByHome: null
      solarConsumption: null
      solarExported: null
      solarGenerated: null
      time: "2019-06-21T09:20:00Z"
      hasInternetAccess: 1
    */

    const nextRow = typeof series[index + 1] === 'object' ? series[index + 1] : {}
    const { instant_battery, hasInternetAccess } = row
    const { time: nextRowTime } = nextRow
    let isNow = false

    if (nextRowTime && !pastNow && moment(nextRowTime).isAfter()) {
      isNow = true
      pastNow = true
    }

    return {
      ...row,
      isNow,
      missingData: instant_battery === null && !pastNow,
      noInternet: !hasInternetAccess && !pastNow
    }
  })

  return {
    ...data,
    series: [...chartData]
  }
}

function sumChartData(data, isCluster ) {
  // data is a list, one object per unit in cluster
  // data = [
  //   {
  //     unit: {},
  //     rawData: {},
  //     totals: {}
  //   },
  //   {
  //     unit: {},
  //     rawData: {},
  //     totals: {}
  //   },
  //   ...
  // ]

  // we remember hardwareId in case the order in the list is not fixed
  // find (e.unit.hardwareId for e in data).index(lastMasterId)
  let master_i = 0
  for (let i = 0; i < data.length; i++) {
    if(data[i].unit.hardwareId == lastMasterId) {
      master_i = i
      break
    }
  }

  const isNumber = (n) => (Number(n) === n && n % 1 === 0) || (Number(n) === n && n % 1 !== 0) // is integer or float

  // prepare accumulator object
  const acc = { series: [], totals: {}, units: [] }

  const numberOfUnits = data.length // used for averaging values where needed

  let lastClusterHash = null

  for (const i in data) {
    const { series, totals, unit } = data[i]
    // if (!(localTotals == null)) totals = localTotals[i]
    let { units } = data[i]
    if (unit) units = [unit] // if `unit` exists then make `units` = [`unit`]

    let isNewCluster = false

    if (units[0].clusterHash !== lastClusterHash) {
      isNewCluster = true
      lastClusterHash = units[0].clusterHash
    }

    // SUM TOTALS

    for (const measurementName in totals) {
      // measurementName =
      //  'batteryCharge'
      //  'batteryDischarge'
      //  'gridExports'
      //  'gridImports'
      //  'homeConsumed'
      //  'solarConsumed'
      //  'solarGenerated'
      //   ...

      // meant to mark which measurements use first unit only
      // means master only and only true if it's not master
      let firstOnly = false

      const isAccValueMissing = (measurementName) => acc.totals[measurementName] === undefined || acc.totals[measurementName] === null

      // create initial acc value if not there
      switch (measurementName) {
        case 'gridExports':
        case 'gridImports':
        case 'solarConsumed':
        case 'solarGenerated':
          // if true we don't add
          // firstOnly = !isNewCluster ? true : false
          firstOnly = !(i == master_i) ? true : false
          if (isAccValueMissing(measurementName)) acc.totals[measurementName] = 0
          break
        // case 'batteryCharge':
        // case 'batteryDischarge':
        case 'homeConsumed':
          if (isAccValueMissing(measurementName)) acc.totals[measurementName] = 0
          firstOnly = !(i == master_i) ? true : false
          break
        case 'batteryDischarge':
          // home consumption needs to include all units battery consumption
          // master's is already included
          // if not master add
          // batteryCharge / batteryDischarge
          // All three go into the same label we add here due to fixed label
          if (isAccValueMissing('homeConsumed')) acc.totals['homeConsumed'] = 0
          if (!(i == master_i)) acc.totals['homeConsumed'] += totals[measurementName]
          // measurementName as defult
          if (isAccValueMissing(measurementName)) acc.totals[measurementName] = 0
          break
        case 'batteryCharge':
          if (isAccValueMissing('homeConsumed')) acc.totals['homeConsumed'] = 0
          if (!(i == master_i)) acc.totals['homeConsumed'] -= totals[measurementName]
          // measurementName as defult
          if (isAccValueMissing(measurementName)) acc.totals[measurementName] = 0
          break
        case 'batteryOutputExported':
          // if not master adjust main solarConsumed
          if (isAccValueMissing('solarConsumed')) acc.totals['solarConsumed'] = 0
          if (!(i == master_i)) acc.totals['solarConsumed'] -= totals[measurementName]
          //default
          if (isAccValueMissing(measurementName)) acc.totals[measurementName] = 0
          break
        default:
          if (isAccValueMissing(measurementName)) acc.totals[measurementName] = 0
      }

      // update acc value
      if (isNumber(totals[measurementName]) && isNumber(acc.totals[measurementName])) {
        if (isCluster && firstOnly === true) continue
        acc.totals[measurementName] += totals[measurementName]
      }
    }

    // COLLECT UNITS

    for (const j in units) {
      if (acc.units === undefined) acc.units = []
      acc.units.push(units[j])
    }

    // SUM SERIES

    if (!series) continue
    // debugger
    // we often/always? see `rawData` and series is missing

    for (const j in series) {
      const measurement = series[j]

      for (const measurementName in measurement) {
        // measurementName =
        //  'batteryInputFromGrid'          number
        //  'batteryInputFromSolar'         number
        //  'batteryOutputConsumedByHome'   number
        //  'batteryOutputExported'         number
        //  'gridConsumedByHome'            number
        //  'homeConsumed'                  number
        //  'instant_battery'               number
        //  'instant_demand'                number
        //  'instant_grid'                  number
        //  'instant_soc'                   number
        //  'instant_solar'                 number
        //  'accessoryClamp1'               null | number
        //  'gridClampDisconnected'         null | 0 | 1
        //  'dnoOverride'                   null | 0 | 1
        //  'epsGridLoss'                   null | 0 | 1
        //  'epsLowSoC'                     null | 0 | 1
        //  'epsOverConsumption'            null | 0 | 1
        //  'hasInternetAccess'             null | 0 | 1
        //  'isNow'                         boolean
        //  'missingData'                   boolean
        //  'noInternet'                    boolean
        //   ...

        // meant to mark which measurements use first unit only
        // means master only
        let firstOnly = false
        // some measurement have variable sign demending on master/slave

        if (acc.series[j] === undefined) acc.series[j] = {}

        const isAccValueMissing = (measurementName) => acc.series[j][measurementName] === undefined || acc.series[j][measurementName] === null

        // create initial acc value if not there
        switch (measurementName) {
          case 'batteryInputFromGrid':
          case 'batteryInputFromSolar':
          case 'gridConsumedByHome':
          case 'instant_battery':
          case 'instant_grid':
          case 'instant_solar':
            // firstOnly = !isNewCluster ? true : false
            firstOnly = !(i == master_i) ? true : false
            if (isAccValueMissing(measurementName)) acc.series[j][measurementName] = 0
            break
          case 'homeConsumed':
            if (isAccValueMissing(measurementName)) acc.series[j][measurementName] = 0
            firstOnly = !(i == master_i) ? true : false
            break
          case 'batteryDischarge':
            // home consumption needs to include all battery consumption
            // batteryCharge / batteryDischarge
            // All three go into the same label:
            if (isAccValueMissing('homeConsumed')) acc.series[j]['homeConsumed'] = 0
            acc.series[j]['homeConsumed'] += measurement[measurementName]
            // we add here due to fixed label
            firstOnly =  true
            break
          case 'batteryCharge':
            if (isAccValueMissing('homeConsumed')) acc.series[j]['homeConsumed'] = 0
            acc.series[j]['homeConsumed'] -= measurement[measurementName]
            firstOnly =  true
            break
          case 'batteryOutputConsumedByHome':
          case 'batteryOutputExported':
          case 'instant_demand':
          case 'instant_soc':
          case 'accessoryClamp1':
          case 'gridClampDisconnected':
          case 'dnoOverride':
          case 'epsGridLoss':
          case 'epsLowSoC':
          case 'epsOverConsumption':
          case 'hasInternetAccess':
            if (isAccValueMissing(measurementName)) acc.series[j][measurementName] = 0
            break
          default:
            if (isAccValueMissing(measurementName)) acc.series[j][measurementName] = measurement[measurementName]
        }

        // update acc value
        if (isNumber(measurement[measurementName]) && isNumber(acc.series[j][measurementName])) {
          if (firstOnly === true) continue
          switch (measurementName) {
            case 'instant_soc':
              acc.series[j][measurementName] += measurement[measurementName] / numberOfUnits
              break
            default:
              acc.series[j][measurementName] += measurement[measurementName]
          }
        }
      }
    }
  }

  return acc
}

function getChartData({ unitsData }) {
  const { calculatedFields } = require('./calculatedFields')

  // unitsData = [
  //   {
  //     unit: {
  //       hardwareId: '12345678',
  //       clusterHash: '3dd4baf3597c8f3d09a0c2d7ab9712bd',
  //       ...
  //     },
  //     rawData: {
  //       FFR_LOCAL_ActivePower_INST_mW_5minMEAN: [Array],
  //       ...
  //     },
  //     totals: {
  //       batteryCharge: 13402450.69799886,
  //       batteryDischarge: 11471081.531820344,
  //       gridExports: 20126.566198627672,
  //       gridImports: 36195652.17772547,
  //       homeConsumed: 49159537.21563193,
  //       solarConsumed: 23740122.326568827,
  //       solarGenerated: 23760011.34090023
  //     }
  //   },
  //   ...
  // ]

  // use first unit in array to determine if clusters have been selected
  const { clusterHash } = unitsData[0].unit
  // if the `clusterHash` field is present than clusters were selected.  (`clusterHash` is created as needed by the UnitSelector component)
  const isCluster = typeof clusterHash === 'string'
  debugMessage(`${isCluster ? 'Clustering IS selected' : 'Clustering is NOT selected'}`, 'getChartData()')

  const singles = unitsData.map((ud) => extendChartData(calculatedFields(ud)))
  // singles = [
  //   { units: [Array], series: [Array], totals: [Object] },
  //   { units: [Array], series: [Array], totals: [Object] },
  //   ...
  // ]

  // let localTotals = singles.map((ud) => ud.totals)

  const clustersRawData = createClustersAndMergeRawData({ unitsData, isCluster })
  // clustersRawData = [
  //   { units: [Array], rawData: [Object], totals: [Object] },
  //   { units: [Array], rawData: [Object], totals: [Object] },
  //   ...
  // ]

  const clusters = clustersRawData.map((crd) => {
    // crd = { units: [Array], rawData: [Object], totals: [Object] }
    return extendChartData(calculatedFields(crd))
  })
  // clusters = [
  //   { units: [Array], series: [Array], totals: [Object] },
  //   { units: [Array], series: [Array], totals: [Object] },
  //   ...
  // ]

  return {
    singles,
    clusters,
    isCluster
  }
}

function findClusters(unitsData) {
  const foundClusters = {}

  // skip if no clusters found
  if (!unitsData[0] || !unitsData[0].unit) {
    return []
  } else {
    // clusters should have `series` = null
    for (const i in unitsData) {
      let clusterHash = unitsData[i].unit.clusterHash ? unitsData[i].unit.clusterHash : 'no-cluster'

      if (!foundClusters[clusterHash]) {
        foundClusters[clusterHash] = []
      }

      foundClusters[clusterHash].push(unitsData[i].unit)
    }

    // convert to array
    return Object.keys(foundClusters).map((k) => foundClusters[k])
  }
}

//////////
// TEST //
//////////

// run getChartData() if script executed directly
// if (require.main === module) getChartData({ unitsData: expectedInput, isCluster: true })

/////////////
// EXPORTS //
/////////////

// module.exports = { getChartData }
export { getChartData, sumChartData, findClusters }
