import React, { useRef, useState, useEffect } from 'react'
import { Popup } from 'semantic-ui-react'
import { friendlyNameFromScheduleState } from '../../common'
import moment from 'moment'

import './EPSScheduleRibbonDay.css'

function EPSScheduleRibbonDay({ dailySchedule, emptyFillState }) {
  const containerRef = useRef()
  const max = 86400
  const [step, setStep] = useState(1)
  const now = moment().diff(moment().startOf('day'), 'seconds')

  useEffect(() => {
    setStep(containerRef.current.offsetWidth / max)
  }, [])

  return (
    <div ref={containerRef} className={`EPSScheduleRibbonDay ${emptyFillState ? `empty-fill-${emptyFillState}` : ''}`}>
      {dailySchedule.length > 0 &&
        dailySchedule.map((item, index) => {
          let { start, end, state } = item

          start = moment(start, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')
          end = moment(end, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')

          if (start > end) {
            // if item wraps around then put a dummy item at the beginning
            return (
              <React.Fragment key={index}>
                {/* dummy item */}
                {/* <Popup inverted content={friendlyNameFromScheduleState(state)} trigger={
                <div className={`item ${state}`} style={{ left: 0, width: (end + 1) * step }} />
              } /> */}
                <div className={`item ${state}`} style={{ left: 0, width: (end + 1) * step }} />

                {/* item with altered end */}
                {/* <Popup inverted content={friendlyNameFromScheduleState(state)} trigger={
                <div className={`item ${state}`} style={{ left: start * step, width: (max - start + 1) * step }} />
              } /> */}
                <div className={`item ${state}`} style={{ left: start * step, width: (max - start + 1) * step }} />
              </React.Fragment>
            )
          } else {
            // if item does not wrap around then render normally
            // return <Popup inverted content={friendlyNameFromScheduleState(state)} trigger={
            //     <div key={index} className={`item ${state}`} style={{ left: start * step, width: (end - start + 1) * step }} />
            // } />
            return <div key={index} className={`item ${state}`} style={{ left: start * step, width: (end - start + 1) * step }} />
          }
        })}
      <div className="now-cursor" style={{ left: now * step }} />
    </div>
  )
}

export default EPSScheduleRibbonDay
