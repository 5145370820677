import React, { useState } from 'react'
import { Modal, Button, Message, Icon } from 'semantic-ui-react'
import { parseModelCode } from '../../common'
import { authenticator } from '../../authenticator'
import { ajax } from '../../ajax'
import { getEnglishText } from '../../dictionary'

import './Warranty.css'

function Warranty({ units, onClose }) {
  const idToken = authenticator.getToken()
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  async function activateWarranty() {
    setSaving(true)
    setErrorMessage(null)
    setSaved(false)
    let successCount = 0

    for (const unit of units) {
      try {
        const { result, errorMessage } = await ajax.putRaw(idToken, `activateWarranty?hardwareId=${unit.unitId}`, {})

        if (result === 'success') {
          successCount++
        } else {
          setErrorMessage(errorMessage)
        }
      } catch (err) {
        setErrorMessage(err)
      }
    }

    setSaving(false)

    if (successCount === units.length) setSaved(true)
  }

  if (saved) {
    return (
      <>
        <Modal open={true} closeOnEscape={true} closeOnDimmerClick={true} size="small" centered={false} dimmer="blurring">
          <Modal.Header>{getEnglishText('warranty : warranty-activated')}</Modal.Header>
          <Modal.Content>
            <Icon name="check" color="green" /> {getEnglishText('warranty : thank-you')}
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon
              labelPosition="right"
              positive
              onClick={() => {
                onClose()
              }}
            >
              {getEnglishText('common : continue')}
              <Icon name="right arrow" />
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    )
  }

  return (
    <>
      <Modal id="Warranty" onClose={onClose} open={true} closeOnEscape={false} closeOnDimmerClick={false} size="small" centered={false} dimmer="blurring">
        <Modal.Header>{getEnglishText('warranty : activate-product-warranty')}</Modal.Header>
        <Modal.Content>
          {getEnglishText('warranty : please-activiate-warranty')}
          <ul>
            {units.map((unit, index) => (
              <li key={index}>
                <strong>{parseModelCode(unit.model).productName}</strong>
              </li>
            ))}
          </ul>
          {errorMessage && <Message error>{errorMessage}</Message>}
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon
            labelPosition="right"
            primary
            loading={saving}
            disabled={saving}
            onClick={() => {
              activateWarranty()
            }}
          >
            {getEnglishText('warranty : activate-warranty-button')}
            <Icon name="check" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export { Warranty }
