import moment from 'moment'
import { authenticator } from './authenticator'
import pckg from '../package.json'
import { getChartData } from './lib/groupUnitData'
import { debugMessage, cleanup, config } from './common'

const CLIENT_VERSION = pckg.version

let API_BASE_URL = 'https://api.portal3.powervault.co.uk'
if (process.env.REACT_APP_STAGE === 'test') API_BASE_URL = 'https://api.test.portal3.powervault.co.uk'
if (process.env.REACT_APP_STAGE === 'staging') API_BASE_URL = 'https://api.staging.portal3.powervault.co.uk'

const _debugMessage = (message) => debugMessage(message, 'ajax.js')

// handle 401 Forbidden or ANY other fetch error
function ajaxError(err) {
  // check if local token has expired
  const idToken = authenticator.getToken()

  if (!idToken?.decoded?.exp) {
    // localstorage is broken, so clear cache and redirect to login
    _debugMessage('ajaxError() :: localstorage corrupted. Clearing cache and signing in ...')
    cleanup({ clearCache: true, signOut: true, initiator: 'ajaxError()' })
  } else if (parseInt(moment().utc().format('X'), 10) > idToken.decoded.exp) {
    // token has expired so reinitiate signin (it should happen automatically)
    _debugMessage('ajaxError() :: Token has expired. Signing in ...')
    cleanup({ signIn: true, initiator: 'ajaxError()' })
  } else {
    _debugMessage('ajaxError() :: Failed to fetch')
  }
}

const ajax = {
  fetchVersion: ({ clientVersion }) => {
    _debugMessage('fetchVersion()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/?clientVersion=${clientVersion}`, {
        headers: {
          Accept: 'application/json',
          'client-version': CLIENT_VERSION
        }
      })
        .then((res) => res.json())
        .then((versionData) => {
          resolve(versionData)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  fetchUserAuth: (idToken) => {
    _debugMessage('fetchUserAuth()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/users/auth`, {
        method: 'post',
        // body: `code=${idToken.decoded.token}`,
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        }
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  // fetch 5-minutely chart data
  fetchChartData: (idToken, options) => {
    _debugMessage('fetchChartData()')

    return new Promise(async (resolve, reject) => {
      const { units, startDateLocal, endDateLocal, timePreset = '', totalsOnly = false, liveView = false } = options

      const timeFromUtc = startDateLocal ? moment(startDateLocal.clone()).utc().format('x') : ''
      const timeToUtc = endDateLocal ? moment(endDateLocal.clone()).utc().format('x') : ''

      function fetchSingleChartData(unitId) {
        return new Promise((resolve, reject) => {
          const endpoint = totalsOnly
            ? `${API_BASE_URL}/graph/data/totals?uk=1&unitId=${unitId}&timeFromMs=${timeFromUtc}&timeToMs=${timeToUtc}&timePreset=${timePreset}`
            : liveView
            ? `${API_BASE_URL}/graph/data/liveView?uk=1&unitId=${unitId}&timeFromMs=${timeFromUtc}&timeToMs=${timeToUtc}&timePreset=${timePreset}`
            : `${API_BASE_URL}/graph/data?uk=1&unitId=${unitId}&timeFromMs=${timeFromUtc}&timeToMs=${timeToUtc}&timePreset=${timePreset}`

          fetch(endpoint, {
            headers: {
              Accept: 'application/json',
              'X-Api-Key': idToken.jwt,
              'client-version': CLIENT_VERSION
            }
          })
            .then((response) => response.json())
            .then((response) => {
              const { unitsData, unitsRawData } = response || {}
              if (unitsData[0]) {
                const { totals } = unitsData[0]
                resolve({ unitsRawData, totals })
              } else {
                resolve()
              }
            })
            .catch((err) => {
              ajaxError(err)
              resolve()
            })
        })
      }

      try {
        let individualUnitsData = []
        for (const unit of units) {
          const { hardwareId } = unit

          const { unitsRawData, totals } = await fetchSingleChartData(hardwareId)
          let rawData = (unitsRawData && unitsRawData[0] && unitsRawData[0]['influxData']) || null
          individualUnitsData = [...individualUnitsData, { unit, rawData, totals }]
        }

        const chartData = getChartData({ unitsData: individualUnitsData })

        resolve(chartData)
      } catch (err) {
        console.error(err)
        ajaxError(err)
      }
    })
  },

  fetchAnnouncements: (idToken) => {
    _debugMessage('fetchAnnouncements()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/announcement`, {
        headers: {
          Accept: 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        }
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  fetchWeather: (unitId, idToken) => {
    _debugMessage('fetchWeather()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/weather?unitId=%UNIT_ID%`.replace(/%UNIT_ID%/gi, unitId), {
        headers: {
          Accept: 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        }
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  fetchTarifflist: (idToken) => {
    _debugMessage('fetchTarifflist()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/tariff`, {
        headers: {
          Accept: 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        }
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  fetchSettings: (hardwareId, idToken) => {
    _debugMessage('fetchSettings()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/settings?unitId=%UNIT_ID%`.replace(/%UNIT_ID%/gi, hardwareId), {
        headers: {
          Accept: 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        }
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  putSettings: (hardwareId, idToken, settingsData) => {
    _debugMessage('putSettings()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/settings?unitId=%UNIT_ID%`.replace(/%UNIT_ID%/gi, hardwareId), {
        method: 'post',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        },
        body: JSON.stringify(settingsData)
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  putCustomName: (hardwareId, idToken, customName) => {
    _debugMessage('putCustomName()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/customName?unitId=%UNIT_ID%`.replace(/%UNIT_ID%/gi, hardwareId), {
        method: 'post',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        },
        body: customName
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  fetchUnitSchedules: (unitId, idToken) => {
    _debugMessage('fetchUnitSchedules()')
    return new Promise((resolve, reject) => {
      const unitScheduleUrl = `${API_BASE_URL}/schedule?unitId=%UNIT_ID%`.replace(/%UNIT_ID%/gi, unitId)

      fetch(unitScheduleUrl, {
        headers: {
          Accept: 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        }
      })
        .then((res) => res.json())
        .then((scheduleData) => {
          resolve(scheduleData)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  putUnitSchedule: (hardwareId, idToken, type, scheduleData) => {
    _debugMessage('putUnitSchedule()')
    return new Promise((resolve, reject) => {
      const unitScheduleUrl = `${API_BASE_URL}/schedule?unitId=%UNIT_ID%`.replace(/%UNIT_ID%/gi, hardwareId)

      fetch(unitScheduleUrl, {
        method: 'post',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        },
        body: JSON.stringify({
          type,
          schedule: scheduleData
        })
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  fetchRaw: (idToken, path) => {
    _debugMessage('fetchRaw()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/${path}`, {
        headers: {
          Accept: 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        }
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  putRaw: (idToken, path, data) => {
    _debugMessage('putRaw()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/${path}`, {
        method: 'post',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        },
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  fetchStateOverrides: (idToken, hardwareId) => {
    _debugMessage('fetchStateOverrides()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/stateOverride?hardwareId=${hardwareId}`, {
        headers: {
          Accept: 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        }
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  putStateOverride: (idToken, hardwareId, stateOverrideData) => {
    _debugMessage('putStateOverride()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/stateOverride?hardwareId=${hardwareId}`, {
        method: 'post',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          'X-Api-Key': idToken.jwt,
          'client-version': CLIENT_VERSION
        },
        body: JSON.stringify({ stateOverride: [stateOverrideData] })
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  fetchLastMeasurement: ({ hardwareId, measurementName, last = false }) => {
    _debugMessage('fetchLastMeasurement()')
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/lastMeasurement?hardwareId=${hardwareId}&measurementName=${measurementName}`, {
        headers: {
          Accept: 'application/json',
          'X-Api-Key': authenticator.getToken().jwt,
          'client-version': CLIENT_VERSION
        }
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  },

  // NEW STYLE FETCH
  //  TODO: refactor all ajax requests to use this

  rawRequest: ({ method = 'get', path, queryParams = {}, headers = {}, accept = 'application/json' }) => {
    _debugMessage('fetchRaw()')
    return new Promise((resolve, reject) => {
      let _path = path.substring(0, 1) === '/' ? path.substring(1) : path // remove preceeding slash if present
      if (queryParams) _path = _path + '?' + new URLSearchParams(queryParams).toString() // add query params to path

      fetch(`${API_BASE_URL}/${_path}`, {
        method,
        headers: {
          Accept: accept, // '*/*' | 'application/json'
          'X-Api-Key': authenticator.getToken().jwt,
          'client-version': CLIENT_VERSION,
          ...headers
        }
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          ajaxError(err)
          reject(err)
        })
    })
  }
}

export { ajax }
