import React, { useState, useEffect } from 'react'
import { Checkbox, Label, Icon, Modal, Button, Header, Message } from 'semantic-ui-react'
import { authenticator } from '../../authenticator'
import { ajax } from '../../ajax'
import { getEnglishText } from '../../dictionary'

import './BetaProgramme.css'

function BetaProgramme({ unit }) {
  const { hardwareId } = unit || {}
  const idToken = authenticator.getToken()

  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(true)
  const [settings, setSettings] = useState(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (!hardwareId) return

    setLoading(true)
    ajax
      .fetchSettings(hardwareId, idToken)
      .then((settingsData) => {
        setSettings(settingsData)
        setLoading(false)
        setMessage(null)
      })
      .catch((err) => {
        setMessage({ error: true, content: getEnglishText('beta-opt-in : could-not-load') })
      })
  }, [hardwareId])

  function putBetaOptIn(enabled) {
    setLoading(true)
    settings.contract.betaOptIn = enabled ? 1 : 0

    ajax
      .putSettings(hardwareId, idToken, settings)
      .then((response) => {
        const { result, errorMessage } = response
        setLoading(false)
        if (result === 'success') {
          setShowModal(false)
        } else {
          setMessage({ error: true, content: errorMessage })
        }
      })
      .catch((error) => {
        setLoading(false)
        setMessage({ error: true, content: error })
      })
  }

  return (
    <div className="BetaProgramme">
      {message && <Message {...message} />}
      {!loading && (
        <>
          <div className={`container ${settings.contract.betaOptIn ? 'enabled' : 'disabled'}`}>
            <Label
              style={{ display: 'inline-block', verticalAlign: 'top' }}
              color={settings.contract.betaOptIn ? 'red' : null}
              size="tiny"
              // basic={!settings.contract.betaOptIn}
              onClick={() => {
                if (!settings.contract.betaOptIn) setShowModal(true)
              }}
            >
              <Icon name="code" />
              {getEnglishText('beta-opt-in : icon-text')}
            </Label>
            {settings.contract.betaOptIn === 1 && (
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Checkbox
                  toggle
                  checked={settings.contract.betaOptIn === 1}
                  onChange={(e, { checked }) => {
                    if (!checked) putBetaOptIn(false)
                  }}
                />
              </>
            )}
          </div>
        </>
      )}

      {showModal && (
        <>
          <Modal open size="small" centered={false} dimmer="blurring">
            <Header>
              Beta Participation
              <Label color="red" content="beta" size="tiny" />
            </Header>
            <Modal.Content>{getEnglishText('beta-opt-in : modal-content')}</Modal.Content>
            <Modal.Actions>
              <Button secondary disabled={loading} loading={loading} onClick={() => setShowModal(false)}>
                {getEnglishText('common : cancel')}
              </Button>
              <Button disabled={loading} loading={loading} primary onClick={() => putBetaOptIn(true)}>
                {getEnglishText('beta-opt-in : join-beta-program')}
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )}
    </div>
  )
}

export { BetaProgramme }
