import React from 'react'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'

import { InternalLink, ExternalLink } from '../common/ExternalLink.js'
import { getEnglishText } from '../../dictionary'

import './Footer.css'

function Footer() {
  return (
    <div id="Footer">
      <Grid columns={3} stackable verticalAlign="middle">
        <Grid.Column textAlign="left" width={4}>
          <ExternalLink className="inverted" href={getEnglishText('footer : facebook-link')} iconName="facebook" iconSize="big" />
          <ExternalLink className="inverted" href={getEnglishText('footer : instagram-link')} iconName="instagram" iconSize="big" />
          <ExternalLink className="inverted" href={getEnglishText('footer : linkedin-link')} iconName="linkedin" iconSize="big" />
        </Grid.Column>
        <Grid.Column width={8}>
          <ExternalLink className="inverted" href={getEnglishText('footer : privacy-link')} content="Privacy Policy" />
          <ExternalLink className="inverted" href={getEnglishText('footer : terms-link')} content="Terms &amp; Conditions" />

          <InternalLink className="inverted mobile-only" to="/home/help" content="Help" />

          <div className="copyright">Copyright &copy; {moment().format('YYYY')} Powervault Ltd. All rights reserved.</div>
        </Grid.Column>
        {/* <Grid.Column textAlign="right" width={4}></Grid.Column> */}
      </Grid>
    </div>
  )
}

export { Footer }
