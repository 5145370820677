import React, { useEffect } from 'react'

import './PowervaultLogo.css'

function PowervaultLogo() {
  return (
    <div className="PowervaultLogo">
      <svg width="262" height="21" viewBox="0 0 262 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.428299 0.625244H11.7613C16.037 0.625244 18.8208 2.86183 18.8208 6.59756C18.8208 10.3333 16.037 12.5969 11.7613 12.5969H2.83885V19.7141H0.428299V0.625244ZM11.675 10.5785C14.8606 10.5785 16.4103 9.07841 16.4103 6.59756C16.4103 4.14281 14.8606 2.64367 11.675 2.64367H2.83885V10.5785H11.675Z"
          fill="white"
        />
        <path
          d="M56.0573 0.625244H58.4963L62.5149 14.8326C62.5149 14.8326 62.8877 16.2506 63.031 16.9321C63.1742 16.2506 63.547 14.8055 63.547 14.8055L67.4498 0.625244H70.0331L73.9359 14.8055C73.9359 14.8055 74.3087 16.2506 74.4529 16.9321C74.5961 16.2506 74.969 14.8326 74.969 14.8326L78.9866 0.625244H81.4256L75.7725 19.7141H73.1892L68.7419 3.54334L64.2936 19.7141H61.7104L56.0573 0.625244Z"
          fill="white"
        />
        <path d="M90.7515 0.625244H109.058V2.64367H93.1621V8.83322H108.254V10.8516H93.1621V17.6966H109.144V19.7141H90.7515V0.625244Z" fill="white" />
        <path
          d="M119.857 0.625244H131.649C135.724 0.625244 138.106 2.88887 138.106 5.94309C138.106 8.75211 136.298 10.6335 133.285 11.288L138.249 19.7141H135.581L130.645 11.4511H122.267V19.7141H119.857V0.625244ZM131.362 9.46066C134.117 9.46066 135.696 8.34283 135.696 6.05216C135.696 3.73447 134.117 2.61571 131.362 2.61571H122.267V9.46066H131.362Z"
          fill="white"
        />
        <path
          d="M145.726 0.625244H148.252L153.733 15.0787C153.733 15.0787 154.164 16.2776 154.48 17.2873C154.795 16.2776 155.225 15.0787 155.225 15.0787L160.707 0.625244H163.232L155.799 19.7141H153.159L145.726 0.625244Z"
          fill="white"
        />
        <path
          d="M176.895 0.625244H179.362L187.398 19.7141H184.844L182.548 14.0961H173.709L171.413 19.7141H168.86L176.895 0.625244ZM181.744 12.1326L178.674 4.6071C178.472 4.17078 178.243 3.54334 178.128 3.1611C178.013 3.54334 177.784 4.17078 177.584 4.6071L174.513 12.1326H181.744Z"
          fill="white"
        />
        <path d="M223.187 0.625244H225.598V17.6686H241.58V19.7141H223.187V0.625244Z" fill="white" />
        <path d="M251.17 2.67071H243.179V0.625244H261.572V2.67071H253.581V19.7141H251.17V2.67071Z" fill="white" />
        <path
          d="M213.267 0.624023V13.0331C213.267 17.558 210.202 20.0922 205.726 20.0922H202.426C197.95 20.0922 194.874 17.558 194.874 13.0331V0.624023H197.286V12.8972C197.286 14.8392 198.052 16.3151 199.329 17.1695C200.167 17.7521 201.22 18.0532 202.426 18.0532H205.726C206.932 18.0532 207.994 17.7424 208.832 17.1598C210.099 16.3054 210.855 14.8294 210.855 12.8972V0.624023H213.267Z"
          fill="white"
        />
        <path
          d="M40.82 0.861572V3.35707C43.446 4.60968 45.2444 7.19242 45.2444 10.1734C45.2444 14.4377 41.5617 17.8963 37.0439 17.8135C32.794 17.7356 29.3133 14.464 29.1757 10.4268C29.0705 7.3393 30.896 4.64504 33.5856 3.35707V0.861572C29.6006 2.25974 26.76 5.91065 26.76 10.1734C26.76 15.7847 31.656 20.299 37.5993 20.0899C43.0545 19.8979 47.4736 15.6863 47.6503 10.5015C47.8004 6.09881 44.9174 2.29548 40.82 0.861572Z"
          fill="#009D9C"
        />
        <path
          d="M38.4085 0.317798V10.1734H35.9971V0.317798C36.3956 0.269439 36.7941 0.25 37.2028 0.25C37.6115 0.25 38.0101 0.269439 38.4085 0.317798Z"
          fill="#009D9C"
        />
      </svg>
    </div>
  )
}

export { PowervaultLogo }
