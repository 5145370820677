import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ajax } from '../../ajax'
import { authenticator } from '../../authenticator'
import { Weather } from '../Weather/Weather'
import { parseModelCode, debugMessage, friendlyFormat } from '../../common'
import { Message, Loader, Header, Icon, Divider, Dimmer, Popup, Segment } from 'semantic-ui-react'
import { sumChartData } from '../../lib/groupUnitData'

import { ForceChargeDischarge } from './ForceChargeDischarge'

import { getEnglishText } from '../../dictionary'

import './LiveView.css'

let refreshHandle = null
let lastRequestTime = 0

function LiveView({ units, setDnoOverride, setEpsGridLoss, setEpsOverConsumption, setEpsLowSoC, isStaff }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [data, setData] = useState([])
  const [lastDataTime, setLastDataTime] = useState(moment())

  const [error, setError] = useState(null)
  const [warning, setWarning] = useState(null)
  const [retries, setRetries] = useState(0)

  const [clusters, setClusters] = useState([])

  const [showMore, setShowMore] = useState(false)

  const refreshInterval = 10000

  useEffect(() => {
    debugMessage('Mounted', 'Live View')
    return () => {
      clearTimeout(refreshHandle)
    }
  }, [])

  useEffect(() => {
    clearTimeout(refreshHandle)
    debugMessage('Updated', 'Live View')
    setIsLoaded(false)
    fetchData()
  }, [units])

  function fetchData() {
    setWarning()
    if (!units || !units[0]) return setWarning(getEnglishText('live-view : no-unit-id'))

    const idToken = authenticator.getToken()

    let params = {
      units,
      timePreset: 'last-60-seconds',
      liveView: true
    }

    lastRequestTime = new Date().getTime()
    let thisRequestTime = new Date().getTime()

    if (retries === 1) {
      params.timePreset = 'last-2-minutes'
    } else if (retries === 2) {
      params.timePreset = 'last-3-minutes'
    } else if (retries === 3) {
      params.timePreset = 'last-4-minutes'
    } else if (retries > 3) {
      params.timePreset = 'last-5-minutes'
    }

    setError(null)

    ajax
      .fetchChartData(idToken, { ...params })
      .then(({ clusters }) => {
        if (lastRequestTime !== thisRequestTime) {
          setError(null)
          debugMessage('Data from old request, discarding', 'Live View')
          return
        }

        let chartData = null
        // sum chart data if more than one unit
        if (clusters.length > 1) {
          chartData = sumChartData(clusters)
          setClusters(clusters)
        } else {
          chartData = clusters[0]
          setClusters([])
        }

        setError(null)

        let dnoOverride = 0
        let epsGridLoss = 0
        let epsOverConsumption = 0
        let epsLowSoC = 0

        setDnoOverride(dnoOverride > 0)
        setEpsGridLoss(epsGridLoss > 0)
        setEpsOverConsumption(epsOverConsumption > 0)
        setEpsLowSoC(epsLowSoC > 0)

        setData(chartData)
        setIsLoaded(true)
      })
      .catch((error) => {
        debugMessage(error)
        setError(error)
      })

    clearTimeout(refreshHandle) // clear existing refresh handle
    refreshHandle = setTimeout(() => fetchData(), refreshInterval) // set next refresh
  }

  function TooLong(props) {
    return (
      <Dimmer active inverted>
        <Loader>{getEnglishText('live-view : too-long')}</Loader>
      </Dimmer>
    )
  }

  function WayTooLong(props) {
    return (
      <Dimmer active inverted>
        <Loader>{getEnglishText('live-view : way-too-long')}</Loader>
      </Dimmer>
    )
  }

  function showAccurateSoc() {
    const { model, displayTrueSoC } = units[0]
    if (displayTrueSoC === 1) return true
    // otherwise, check model
    const { accurateSoc } = parseModelCode(model)
    return accurateSoc
  }

  function parseAccurateSoc(instant_soc) {
    const { model } = units[0]
    const { modifiedSoCRange } = parseModelCode(model)

    if (modifiedSoCRange) {
      const minVal = 7 // treat 7 as 0%
      const maxVal = 90 // treat 90 as 100%

      function getModifiedPercent(value) {
        return (100 * (value - minVal)) / (maxVal - minVal)
      }

      debugMessage(`Using modified SoC range ${minVal}-${maxVal}`, 'Live View')

      let modifiedSoc = getModifiedPercent(instant_soc)
      modifiedSoc = parseInt(modifiedSoc, 10)

      if (modifiedSoc < 1) modifiedSoc = 1
      if (modifiedSoc > 100) modifiedSoc = 100

      return modifiedSoc
    }

    return parseInt(instant_soc, 10)
  }

  function LiveViewChart() {
    let instant_battery = 0
    let instant_demand = 0
    let instant_grid = 0
    let instant_solar = 0
    let total_soc = 0
    let instant_soc = 0
    let gridClampDisconnected = 0
    // let epsGridLoss = 0
    // let epsOverConsumption = 0
    // let epsLowSoC = 0

    const { series } = data

    instant_battery += series[0].instant_battery
    total_soc += series[0].instant_soc

    gridClampDisconnected += series[0].gridClampDisconnected
    // epsGridLoss += series[0].epsGridLoss
    // epsOverConsumption += series[0].epsOverConsumption
    // epsLowSoC += series[0].epsLowSoC

    instant_grid += series[0].instant_grid
    instant_solar += series[0].instant_solar
    instant_demand += series[0].instant_demand

    instant_soc = total_soc

    const timeSinceDataMs = moment().diff(lastDataTime)

    let stateOfChargeIcon = null
    let stateOfChargeIconClass = null
    let stateOfChargeText = [<>{getEnglishText('live-view : state-of-charge')}:</>]
    let stateOfChargeTextClass = ''

    instant_soc = parseAccurateSoc(instant_soc)

    if (instant_soc < 20) {
      stateOfChargeIcon = 'battery empty'
      // stateOfChargeIconClass = 'pv-red-color'
      // stateOfChargeTextClass = '#d84b49'
      stateOfChargeIconClass = 'pv-primary-color'
      stateOfChargeTextClass = 'pv-primary-color'
      stateOfChargeText.push(<> 0% - 20%</>)
    } else if (instant_soc < 40) {
      stateOfChargeIcon = 'battery one'
      // stateOfChargeIconClass = 'pv-red-color'
      // stateOfChargeTextClass = '#d84b49'
      stateOfChargeIconClass = 'pv-primary-color'
      stateOfChargeTextClass = 'pv-primary-color'
      stateOfChargeText.push(<> 20% - 40%</>)
    } else if (instant_soc < 60) {
      stateOfChargeIcon = 'battery two'
      // stateOfChargeIconClass = 'pv-yellow-color'
      // stateOfChargeTextClass = '#f0b407'
      stateOfChargeIconClass = 'pv-primary-color'
      stateOfChargeTextClass = 'pv-primary-color'
      stateOfChargeText.push(<> 40% - 60%</>)
    } else if (instant_soc < 80) {
      stateOfChargeIcon = 'battery three'
      // stateOfChargeIconClass = 'pv-green-color'
      // stateOfChargeTextClass = '#7bb55d'
      stateOfChargeIconClass = 'pv-primary-color'
      stateOfChargeTextClass = 'pv-primary-color'
      stateOfChargeText.push(<> 60% - 80%</>)
    } else {
      stateOfChargeIcon = 'battery four'
      // stateOfChargeIconClass = 'pv-green-color'
      // stateOfChargeTextClass = '#7bb55d'
      stateOfChargeIconClass = 'pv-primary-color'
      stateOfChargeTextClass = 'pv-primary-color'
      stateOfChargeText.push(<> 80% - 100%</>)
    }

    if (showAccurateSoc()) {
      stateOfChargeText = [
        <>
          {getEnglishText('live-view : state-of-charge')}: {instant_soc}%
        </>
      ]
    }

    if (clusters.length > 1)
      stateOfChargeText.push(
        <>
          <br />
          {`(averaged accross ${clusters.length} clusters)`}
        </>
      )

    // 25kW is close to the max that a home can draw
    const magnitudeExp = 0.00001

    const clampWidth = (width) => {
      const minWidth = 3
      const maxWidth = 30
      if (width === 0) {
        return 0
      } else if (width > maxWidth) {
        return maxWidth
      } else if (width < minWidth) {
        return minWidth
      } else {
        return width ? width : 0
      }
    }

    // filter input values to zero
    if (instant_grid < 100000 && instant_grid > -100000) instant_grid = 0 //          Zero imports below 10W, Zero exports below 100W  Allow + and -
    if (instant_solar < 100000) instant_solar = 0 //                                  Zero inports below 10W                            Only allow +
    if (instant_battery < 100000 && instant_battery > -100000) instant_battery = 0 //  Zero imports and exports below 10W                Allow + and -
    if (instant_demand > -100000) instant_demand = 0 //                               Zero consumption below 10W                        Only allow -

    // make instant_grid = 0 if gridClampDisconnected = 1
    if (gridClampDisconnected) instant_grid = 0

    let gridWidth = clampWidth(Math.abs(instant_grid * magnitudeExp))
    let solarWidth = clampWidth(Math.abs(instant_solar * magnitudeExp))
    let batteryWidth = clampWidth(Math.abs(instant_battery * magnitudeExp))
    let homeWidth = clampWidth(Math.abs(instant_demand * magnitudeExp))

    const gridDisplay = friendlyFormat('instant_grid', instant_grid).join(' ')
    const solarDisplay = friendlyFormat('instant_solar', instant_solar).join(' ') // forces positive value
    const batteryDisplay = friendlyFormat('instant_battery', instant_battery).join(' ')
    const homeDisplay = friendlyFormat('instant_demand', instant_demand).join(' ') // forces positive value

    return (
      <>
        {instant_soc > 0 && (
          <Popup
            inverted
            content={stateOfChargeText.map((element, i) => ({ ...element, key: i }))} // avoid 'unique "key" prop' errors
            trigger={
              <div className="soc">
                {showAccurateSoc() && <span className={stateOfChargeTextClass}>{instant_soc}%</span>}
                <Icon className={`battery-icon ${stateOfChargeIconClass}`} name={`${stateOfChargeIcon}`} size="big" />
              </div>
            }
          />
        )}
        <Divider hidden />

        {timeSinceDataMs > 20000 && retries > 3 ? <WayTooLong /> : timeSinceDataMs > 10000 && retries > 3 ? <TooLong /> : null}

        {gridClampDisconnected > 0 && (
          <Message error>
            <Message.Header>
              <>{getEnglishText('live-view : grid-clamp-diconnected-title')}</>
            </Message.Header>
            <Message.Content>
              <>{getEnglishText('live-view : grid-clamp-diconnected-body')}</>
            </Message.Content>
          </Message>
        )}

        <div className="live-view-container">
          <div className="svg-container">
            {/* Uncomment to display max values */}
            {/* {(() => {
              instant_grid = 1000
              instant_solar = 1000
              instant_battery = 1000
              instant_demand = 1000
              gridWidth = 10
              solarWidth = 10
              batteryWidth = 10
              homeWidth = 10
            })()} */}

            {/* <svg height="280" width="400">
              <g style={{ display: instant_grid ? '' : 'none' }}>
                <path className="pv-grid-stroke" d="M100,50 h65 a20,20 0 0 1 20,20 v60" strokeWidth={gridWidth} />
                <path className={'flow grid' + (instant_grid > 0 ? ' out' : ' in')} d="M100,50 h65 a20,20 0 0 1 20,20 v60" strokeWidth={gridWidth} />
              </g>
              <g style={{ display: instant_solar ? '' : 'none' }}>
                <path className="pv-solar-stroke" d="M300,50 h-65 a20,20 0 0 0 -20,20 v60" strokeWidth={solarWidth} />
                <path className={'flow solar' + (instant_solar > 0 ? ' out' : ' in')} d="M300,50 h-65 a20,20 0 0 0 -20,20 v60" strokeWidth={solarWidth} />
              </g>
              <g style={{ display: instant_battery ? '' : 'none' }}>
                <path className="pv-battery-stroke" d="M100,210 h65 a20,20 0 0 0 20,-20 v-60" strokeWidth={batteryWidth} />
                <path
                  className={'flow battery' + (instant_battery > 0 ? ' out' : ' in')}
                  d="M100,210 h65 a20,20 0 0 0 20,-20 v-60"
                  strokeWidth={batteryWidth}
                />
              </g>
              <g style={{ display: instant_demand ? '' : 'none' }}>
                <path className="pv-home-stroke" d="M300,210 h-65 a20,20 0 0 1 -20,-20 v-60" strokeWidth={homeWidth} />
                <path className={'flow home' + (instant_demand > 0 ? ' out' : ' in')} d="M300,210 h-65 a20,20 0 0 1 -20,-20 v-60" strokeWidth={homeWidth} />
              </g>
            </svg> */}

            <svg height="320" width="400">
              <g style={{ display: instant_grid ? '' : 'none' }}>
                <path className="pv-grid-stroke" d="M100,50 h65 a20,20 0 0 1 20,20 v90" strokeWidth={gridWidth} />
                <path className={'flow grid' + (instant_grid > 0 ? ' out' : ' in')} d="M100,50 h65 a20,20 0 0 1 20,20 v90" strokeWidth={gridWidth} />
              </g>
              <g style={{ display: instant_solar ? '' : 'none' }}>
                <path className="pv-solar-stroke" d="M300,50 h-65 a20,20 0 0 0 -20,20 v90" strokeWidth={solarWidth} />
                <path className={'flow solar' + (instant_solar > 0 ? ' out' : ' in')} d="M300,50 h-65 a20,20 0 0 0 -20,20 v90" strokeWidth={solarWidth} />
              </g>
              <g style={{ display: instant_battery ? '' : 'none' }}>
                <path className="pv-battery-stroke" d="M100,250 h65 a20,20 0 0 0 20,-20 v-90" strokeWidth={batteryWidth} />
                <path
                  className={'flow battery' + (instant_battery > 0 ? ' out' : ' in')}
                  d="M100,250 h65 a20,20 0 0 0 20,-20 v-90"
                  strokeWidth={batteryWidth}
                />
              </g>
              <g style={{ display: instant_demand ? '' : 'none' }}>
                <path className="pv-home-stroke" d="M300,250 h-65 a20,20 0 0 1 -20,-20 v-90" strokeWidth={homeWidth} />
                <path className={'flow home' + (instant_demand > 0 ? ' out' : ' in')} d="M300,250 h-65 a20,20 0 0 1 -20,-20 v-90" strokeWidth={homeWidth} />
              </g>
            </svg>
          </div>

          <div className="container">
            <div className="node joint" style={{ display: isLoaded ? '' : 'none' }} />
            <div className={'node solar pv-solar-node' + (instant_solar ? '' : ' empty')}>
              <Icon name="sun" size="big" />
              <span>{solarDisplay}</span>
            </div>
            <div className={'node grid pv-grid-node' + (instant_grid ? '' : ' empty') + (gridClampDisconnected > 0 ? ' disconnected' : '')}>
              {gridClampDisconnected > 0 && (
                <Icon
                  name="warning"
                  color="red"
                  size="large"
                  circular
                  style={{ position: 'absolute', top: '-44px', right: '-19px', backgroundColor: '#fff' }}
                />
              )}
              <Icon name="plug" size="big" />
              <span>{gridDisplay}</span>
            </div>
            <div className={'node home pv-home-node' + (instant_demand ? '' : ' empty')}>
              <Icon name="home" size="big" />
              <span>{homeDisplay}</span>
            </div>
            <div className={'node battery pv-battery-node' + (instant_battery ? '' : ' empty')}>
              <Icon className="battery-icon" name={`${stateOfChargeIcon}`} size="big" />
              <span>{batteryDisplay}</span>
            </div>
          </div>
          {isLoaded ? '' : <Loader active inline="centered" size="large" />}
        </div>
      </>
    )
  }

  return (
    <Segment id="live-view" className={`${showMore ? 'show-more' : ''}`}>
      <Header as="h2">
        <Icon name="power" color="teal" />
        <Header.Content>
          {getEnglishText('live-view : title')}&nbsp;&nbsp;
          {isLoaded && units.length > 1 && (
            <span className="clusters-desc" style={{ fontSize: clusters.length > 1 ? '75%' : '100%' }}>
              <>
                ({units.length} {getEnglishText('common : units')}
                {clusters.length > 1 && <>{`, in ${clusters.length} clusters`}</>})&nbsp;&nbsp;
              </>
            </span>
          )}
          <Header.Subheader>{getEnglishText('live-view : subtitle')}</Header.Subheader>
        </Header.Content>
      </Header>

      {error && (
        <Message negative>
          <p>
            {getEnglishText('common : error')}: {error.message}
          </p>
        </Message>
      )}

      {warning && (
        <Message warning>
          <p>{warning}</p>
        </Message>
      )}

      {!isLoaded ? (
        <Loader active inline="centered" />
      ) : (
        <>
          <LiveViewChart />
          <Divider hidden />
          <Divider hidden />
          <Weather unit={units[0]} />
          <Divider hidden />
          <ForceChargeDischarge hardwareIds={units} />
          <Divider hidden className="mobile-hidden" />
        </>
      )}

      <div className="show-more-button mobile-only" style={{ textAlign: 'right' }}>
        <Link onClick={() => setShowMore(!showMore)}>
          Show {`${showMore ? getEnglishText('common : less').toLowerCase() : getEnglishText('common : more').toLowerCase()}`} ...
        </Link>
      </div>
    </Segment>
  )
}

export { LiveView }
