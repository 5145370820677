import React, { useState, useEffect } from 'react'
import { Modal, Button, Header, Icon, Segment } from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'

import pckg from '../../../package.json'
import './ReleaseNotes.css'
import { getChangeLog } from '../../common.js'

import { getEnglishText } from '../../dictionary'

import { ExternalLink } from '../common/ExternalLink.js'

function ReleaseNotes() {
  const navigate = useNavigate()
  const [changeLog, setChangeLog] = useState(null)
  const { version } = pckg

  useEffect(() => {
    getChangeLog().then((data) => setChangeLog(data))
    return () => {}
  })

  return (
    <React.Fragment>
      <Modal
        id="release-notes-modal"
        open
        centered={false}
        dimmer="blurring"
        onClose={(e) => {
          e.stopPropagation()
          navigate('/home/account')
        }}
      >
        <Modal.Header>
          {getEnglishText('release-notes : title')} (v{version})
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            {changeLog
              ? changeLog.map((item, index) => {
                  const { version, date, new_features, improvements, bug_fixes, known_issues } = item

                  return (
                    <React.Fragment key={index}>
                      <Header as="h3">
                        v{version}
                        {date ? <span className="date">{date}</span> : null}
                      </Header>
                      <Segment>
                        {new_features && new_features.length ? (
                          <React.Fragment>
                            <Header as="h4">
                              <Icon name="star outline" color="yellow" /> {getEnglishText('release-notes : new-features')}
                            </Header>
                            <ul>
                              {new_features.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </React.Fragment>
                        ) : null}
                        {improvements && improvements.length ? (
                          <React.Fragment>
                            <Header as="h4">
                              <Icon name="thumbs up outline" color="blue" /> {getEnglishText('release-notes : improvements')}
                            </Header>
                            <ul>
                              {improvements.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </React.Fragment>
                        ) : null}
                        {bug_fixes && bug_fixes.length ? (
                          <React.Fragment>
                            <Header as="h4">
                              <Icon name="bug" color="violet" /> {getEnglishText('release-notes : bug-fixes')}
                            </Header>
                            <ul>
                              {bug_fixes.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </React.Fragment>
                        ) : null}
                        {known_issues && known_issues.length ? (
                          <React.Fragment>
                            <Header as="h4">
                              <Icon name="eye" color="orange" /> {getEnglishText('release-notes : known-issues')}
                            </Header>
                            <ul>
                              {known_issues.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </React.Fragment>
                        ) : null}
                      </Segment>
                    </React.Fragment>
                  )
                })
              : null}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <p>
            <Icon name="info" /> We're always eager to know what you think. Please{' '}
            <ExternalLink href={`mailto:service@powervault.co.uk?subject=Portal%20Feedback%20(v${version})`}>email us</ExternalLink> your feedback!
          </p>
          <Button secondary as={Link} to="/home/account" onClick={(e) => e.stopPropagation()}>
            {getEnglishText('common : close')}
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  )
}

export { ReleaseNotes }
