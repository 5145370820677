import React, { useState, useEffect } from 'react'
import { Modal, Button, Menu, Segment, Image, Grid, Message, Loader, Header, Icon, Divider, Dimmer, Popup } from 'semantic-ui-react'
import { ExternalLink } from '../../common/ExternalLink'

function HelpPowervaultOffline() {
  return (
    <>
      <Header>If your Powervault appears to be offline please check the following:</Header>

      <ul>
        <li>
          <p>
            <strong>Is the unit switched on?</strong>
          </p>
          <p>
            Listen to hear if the fan is running. If your unit has cables extending from the right-hand side of the case please check if any lights are showing.
          </p>
        </li>
        <li>
          <p>
            <strong>If not, please check your consumer unit (fuse box) for any tripped fuse switches.</strong>
          </p>
          <p>
            These would be in the down position if tripped and can be reset by moving them to the upward position. If you need support with this you can send us
            a photo during office hours.
          </p>
        </li>
        <li>
          <p>
            <strong>
              If there are no tripped fuses, please check that the red rotary switch or external breaker on the left side of the unit is in the upward ON
              position.
            </strong>
          </p>
          <p>If not, move the switch to the upward ON position.</p>
        </li>
        <li>
          <p>
            <strong>If the red rotary switch or external breaker is already in the upward ON position, please try resetting the unit.</strong>
          </p>
          <p>
            Reset the unit by moving the red rotary switch or external breaker on the left side of the unit to the OFF position, then back to the ON position.
            Once this is done, wait 5 - 10 minutes for your Powervault to come online.
          </p>
        </li>
      </ul>

      <Header>If these steps do not resolve the issue, please check the internet connection to the unit:</Header>

      <ul>
        <li>
          <p>
            <strong>Check that your router is on and working.</strong>
          </p>
          <p>It can sometime help to reset the router by switching it off, then on again. Check that other devices in your home have internet access.</p>
        </li>
        <li>
          <p>
            <strong>Check the Ethernet cable is connected securely at both ends.</strong>
          </p>
          <p>The Ethernet cable should be securely clipped into the Powervault and your router (or wifi / powerline extender).</p>
        </li>
        <li>
          <p>
            <strong>If your set-up uses wifi or powerline extenders (such as TP Link), try resetting or re-pairing these devices.</strong>
          </p>
          <p>
            To reset and re-pair <strong>TP Link</strong> extenders:
          </p>

          <ul>
            <li>Hold down each button for 15 seconds to reset the devices.</li>
            <li>Turn these off, and press the buttons on both devices within two minutes of each other.)</li>
            <li>The three lights on the front of the TP Link should all be green when there's a good connection.</li>
          </ul>

          <p>For further guidance please see these third-party videos:</p>

          <ul>
            <li>
              <ExternalLink href="https://www.youtube.com/watch?v=G7VpW1RTnmA">TP-Link Power Line Adapter Turn Off Power Save Mode</ExternalLink>
            </li>
            <li>
              <ExternalLink href="https://www.youtube.com/watch?v=bAPl6ATT4C4">Factory Reset the TP-Link (TL-PA4010) Powerline Adpter</ExternalLink>
            </li>
            <li>
              <ExternalLink href="https://www.youtube.com/watch?v=UroFT8vJyWc">TP Links: Re-pairing for Solar Panel monitoring</ExternalLink>
            </li>
          </ul>
        </li>
      </ul>

      <p>
        If you have recently installed any hardware or software on your home network that restricts or limits internet traffic (
        <strong>such as a parental control service or a firewall</strong>) please ensure that these do not block your Powervault from reaching the internet.
      </p>

      <p>If all of the steps above do not resolve the issue, please contact customer service.</p>

      <Button primary basic as="a" href="mailto:service@powervault.co.uk?subject=Powervault%20Offline" target="_blank" referrerPolicy="no-referrer">
        Contact Customer Service
      </Button>
    </>
  )
}

export { HelpPowervaultOffline }
