import React, { useState, useEffect } from 'react'
import { Modal, Button, Menu, Segment, Image, Grid, Message, Loader, Header, Icon, Divider, Dimmer, Popup } from 'semantic-ui-react'

function HelpSmartSTOR() {
  return (
    <>
      <Header>How does SMARTSTOR&trade; work?</Header>
      <p>SMARTSTOR&trade; intelligently calculates the optimal schedule for the next day based on various factors such as:</p>
      <ul>
        <li>Your energy consumption history</li>
        <li>Past weather, cross-referenced with your solar generation history</li>
        <li>The upcoming weather forcast</li>
        <li>Your dynamic or Time of Use tariff</li>
      </ul>
      <p>
        The consumption / generation model is re-trained every 2 weeks, and a new schedule is delivered to your Powervault every 24 hours based on the latest
        tariff and weather information.
      </p>
      <Header>Why is my schedule not as expected?</Header>
      <p>This could be due to one or a combination of the following factors:</p>
      <ul>
        <li>Insufficient consumption / generation data</li>
        <li>Unpredictable weather forecasts (forecasts are not 100% reliable!)</li>
        <li>An incorrect tariff selected</li>
        <li>The postcode associated with your account is incorrect</li>
        <li>Unexpected consumption, e.g. vehicles charging</li>
        <li>Unexpected installation of new equipment, e.g. more solar panels</li>
      </ul>
      <Header>Can I override my SMARTSTOR&trade; schedule?</Header>
      <p>
        Yes, please look for the <strong>Temporary Boost</strong> function which allows you to initiate a short burst of charging or discharging as desired. You
        can also disable SMARTSTOR&trade; if you wish.
      </p>
      <Header>My tarrif is not available in the list?</Header>
      <p>Please contact Powervault to inform us of your tarrif!</p>
    </>
  )
}

export { HelpSmartSTOR }
