import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { authenticator } from './authenticator'
import { debugMessage, queryString } from './common'

const PROCESS_ACTION_DELAY_MS = 500

function Action() {
  const navigate = useNavigate()

  useEffect(() => {
    debugMessage('Mounted', 'Action')

    const qs = queryString()

    // check for `action` in querystring
    if (qs && qs.action) {
      // ?action= exists, so save to localstorage
      window.localStorage.setItem('action', JSON.stringify(qs.action))
      debugMessage('Saved action to localstorage, trying to process action ...', 'Action')
      debugMessage(qs.action, 'Action')

      setTimeout(() => processAction(qs.action), PROCESS_ACTION_DELAY_MS)
      return
    } else {
      // check for `action` in localstorage
      let action = window.localStorage.getItem('action')
      if (action) {
        try {
          action = JSON.parse(action)
        } catch (err) {}
        debugMessage('Action found in localstorage, processing ...', 'Action')
        setTimeout(() => processAction(action), PROCESS_ACTION_DELAY_MS)
        return
      } else {
        debugMessage('No action found in localstorage, redirecting to /home ...', 'Action')
        navigate('/home')
      }
    }
  }, [])

  function processAction(action) {
    if (!authenticator.isAuthenticated()) {
      debugMessage('Cannot process action, not authenticated, redirecting to /home to force sign in ...', 'Action')
      navigate('/home')
      return
    }

    switch (action.action) {
      case 'quick-start':
        debugMessage('Redirecting to /quick-start ...', 'Action')
        navigate(`/quick-start/?action=${JSON.stringify(action)}`)
        return
      case 'goto-unit':
        debugMessage("'goto-unit' action found, redirecting to /home?hardwareId={{action.hardwareId}} ...", 'Action')
        window.localStorage.removeItem('action')

        // clear current selected unit from cache
        window.localStorage.removeItem('UNIT_DATA')
        navigate(`/home?hardwareId=${action.hardwareId}`)
        return
      default:
        debugMessage('No valid action found, redirecting to /home ...', 'Action')
        navigate('/home')
    }
  }

  return <></>
}

export { Action }
