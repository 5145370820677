import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'
import { authenticator } from '../../authenticator'
import { PowervaultLogo } from './PowervaultLogo'

import { getEnglishText } from '../../dictionary'

import './NavBar.css'

function NavBar({ user, noUnits, handleSelectUnitClick }) {
  useEffect(() => {}, [])

  const userEmail = authenticator.getUserDetails()['email']

  return (
    <div className="NavBar">
      <PowervaultLogo />

      {noUnits ? null : (
        <Button floated="left" primary basic inverted className="select-unit-button" onClick={(e) => handleSelectUnitClick()}>
          {getEnglishText('nav-bar : select-powervault')}
        </Button>
      )}

      <Button floated="right" primary className="main-menu-button" icon as={Link} to="/home/account">
        <Icon name="bars" />
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;{userEmail}
          {user.user && user.user.isStaff === true && <span>&nbsp;&nbsp;(staff)</span>}
          {user.user && user.user.isInstaller === true && <span>&nbsp;&nbsp;(installer)</span>}
        </span>
      </Button>

      <Button floated="right" inverted basic icon className="help-button mobile-hidden" as={Link} to="/home/help">
        <Icon name="help" />
      </Button>
    </div>
  )
}

export { NavBar }
