import React, { useState } from 'react'
import { Container, Segment, Header, Button } from 'semantic-ui-react'
import { Link, useRouteError } from 'react-router-dom'

import { cleanup } from '../../common'
import { getEnglishText } from '../../dictionary'

import './ErrorPage.css'

function ErrorPage() {
  let error = useRouteError()

  const { status } = error || {}

  return (
    <Container text div id="ErrorPage">
      <Segment clearing padded="very">
        {(() => {
          switch (status) {
            case 404:
              return (
                <>
                  <Header as="h1">{getEnglishText('error-page : 404-title')}</Header>
                  <p>{getEnglishText('error-page : 404-description')}</p>
                  <Button primary floated="right" content="Go back" as={Link} to="/" />
                </>
              )
            default:
              console.error(error)
              return (
                <>
                  <Header as="h1">{getEnglishText('error-page : general-title')}</Header>
                  <p>{getEnglishText('error-page : general-description')}</p>
                  <Button
                    primary
                    floated="right"
                    content="Reload and try again"
                    onClick={async () => await cleanup({ clearCache: true, redirect: '/', initiator: 'ErrorPage' })}
                  />
                </>
              )
          }
        })()}
      </Segment>
    </Container>
  )
}

export { ErrorPage }
