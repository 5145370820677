import React, { useContext } from 'react'

import { appContext } from '../../AppContextProvider'

import './Background.css'

function Background() {
  const { appData } = useContext(appContext)

  return (
    <div className="Background">
      {(() => {
        switch (appData?.ui?.backgroundStyle) {
          case 'powervault-plus':
            return (
              <>
                <div className="powervault-plus" />
                <div className="ellipse-1" />
                <div className="ellipse-2" />
                <div className="ellipse-3" />
                <div className="ellipse-4" />
              </>
            )
          case 'default':
          default:
            return (
              <>
                <div className="ellipse-1" />
                <div className="ellipse-2" />
                <div className="ellipse-3" />
                <div className="ellipse-4" />
              </>
            )
        }
      })()}
    </div>
  )
}

export { Background }
