import React, { useEffect } from 'react'
import { debugMessage } from './common'

function Login() {
  useEffect(() => {
    debugMessage('Mounted', 'Login :: Logging in ...')
    // This component isn't really necessary anymore, it's just a route for /login which
    //  redirects to /action in order to process any cached actions set before log in sequence
    window.location = '/action'
  }, [])

  return <></>
}

export { Login }
