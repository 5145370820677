import React, { useState, createContext, useEffect } from 'react'
import useWindowFocus from 'use-window-focus'

export const appContext = createContext()

const defaultState = { ui: { backgroundStyle: 'default' } }

export default function AppContextProvider({ children }) {
  const windowFocused = useWindowFocus()
  const [appData, setAppData] = useState({ ...defaultState })

  useEffect(() => {
    // appData state has changed
    return () => {}
  }, [appData])

  useEffect(() => {
    setAppData({ ...appData, windowFocused })
    // console.log('windowFocused', windowFocused)
    return () => {}
  }, [windowFocused])

  return (
    <appContext.Provider value={{ appData, setAppData }}>
      <>{children}</>
    </appContext.Provider>
  )
}
