import React, { useState, useLayoutEffect, useRef } from 'react'

import './ScheduleRibbonScale.css'

function ScheduleRibbonScale({ style = {}, customScaleSpacingHandler }) {
  const ticks = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00'
  ]
  const tickWidth = 80
  const tickMinGap = 20

  const containerRef = useRef(null)
  const [startAtTickIndex, setStartAtTickIndex] = useState(0)
  const [skipEveryNth, setSkipEveryNth] = useState(0)

  useLayoutEffect(() => {
    function calculateTicks() {
      const containerWidth = containerRef.current.offsetWidth

      let start = 0
      let skip = 0

      if (customScaleSpacingHandler) {
        ;[start, skip] = customScaleSpacingHandler(containerWidth)
      } else {
        start = 1
        skip = Math.floor(ticks.length / (containerWidth / (tickWidth + tickMinGap)))
      }

      setStartAtTickIndex(start)
      setSkipEveryNth(skip)
    }
    window.addEventListener('resize', calculateTicks)
    calculateTicks()
    return () => window.removeEventListener('resize', calculateTicks)
  }, [])

  let counter = 0

  return (
    <>
      <div className="ScheduleRibbonScale" ref={containerRef} style={style}>
        {ticks.map((hour, index) => {
          // start 1   skip 2
          if (index < startAtTickIndex) return null

          counter++

          if (skipEveryNth > 0 && (counter - 1) % skipEveryNth !== 0) return null

          // counter++

          const leftPercent = (100 / ticks.length) * index
          return (
            <div key={index} className="tick" style={{ left: `${leftPercent}%`, width: `${tickWidth}px`, marginLeft: `-${tickWidth / 2}px` }}>
              {hour}
              <div></div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ScheduleRibbonScale
