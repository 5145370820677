import React, { useRef, useState, useLayoutEffect } from 'react'
import moment from 'moment'

import './ScheduleRibbonDay.css'

function ScheduleRibbonDay({
  daySchedule,
  renameAllStates,
  emptyFillState,
  showDayName = false,
  nowCursorStyle = {},
  highlight = false,
  dim = false,
  clickHandler = null
}) {
  const containerRef = useRef()
  const max = 86400
  const [step, setStep] = useState(1)
  const now = moment().diff(moment().startOf('day'), 'seconds')

  useLayoutEffect(() => {
    function handleResize() {
      setStep(containerRef.current.offsetWidth / max)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div
      className={`ScheduleRibbonDay ${highlight ? 'highlight' : dim ? 'dim' : ''} ${clickHandler ? 'clickable' : ''}`}
      onClick={() => {
        if (clickHandler) clickHandler(showDayName)
      }}
    >
      {showDayName && (
        <div className={`day-name ${showDayName === moment().format('dddd').toLowerCase() ? 'today' : ''}`}>{showDayName.substr(0, 3).toUpperCase()}</div>
      )}

      <div
        ref={containerRef}
        className={`ribbon-container ${showDayName ? 'show-day-name' : ''} ${
          emptyFillState ? `empty-fill-${emptyFillState}` : ''
          // emptyFillState && (!daySchedule || !daySchedule.length) ? `empty-fill-${emptyFillState}` : ''
        }`}
      >
        {daySchedule && daySchedule.length > 0 ? (
          daySchedule.map((item, index) => {
            let { start, end, state } = item

            start = moment(start, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')
            end = moment(end, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')

            if (start > end) {
              // if item wraps around then put a dummy item at the beginning
              return (
                <React.Fragment key={index}>
                  {/* dummy item */}
                  {/* <Popup
                    inverted
                    content={friendlyNameFromScheduleState(state)}
                    trigger={<div className={`item ${renameAllStates ? renameAllStates : state}`} style={{ left: 0, width: (end + 1) * step }} />}
                  /> */}
                  <div className={`item ${renameAllStates ? renameAllStates : state}`} style={{ left: 0, width: (end + 1) * step }} />
                  {/* item with altered end */}
                  {/* <Popup
                    inverted
                    content={friendlyNameFromScheduleState(state)}
                    trigger={
                      <div className={`item ${renameAllStates ? renameAllStates : state}`} style={{ left: start * step, width: (max - start + 1) * step }} />
                    } 
                  /> */}
                  <div className={`item ${renameAllStates ? renameAllStates : state}`} style={{ left: start * step, width: (max - start + 1) * step }} />
                </React.Fragment>
              )
            } else {
              // if item does not wrap around then render normally
              return (
                // <Popup
                //   inverted
                //   content={friendlyNameFromScheduleState(state)}
                //   key={index}
                //   trigger={
                //     <div className={`item ${renameAllStates ? renameAllStates : state}`} style={{ left: start * step, width: (end - start + 1) * step }} />
                //   }
                // />
                <div
                  key={index}
                  className={`item ${renameAllStates ? renameAllStates : state}`}
                  style={{ left: start * step, width: (end - start + 1) * step }}
                />
              )
            }
          })
        ) : (
          <></>
        )}
        <div className="now-cursor" style={{ left: now * step, ...nowCursorStyle }} />
      </div>
    </div>
  )
}

export default ScheduleRibbonDay
