import React, { useEffect, useState } from 'react'
import {} from 'semantic-ui-react'
import { authenticator } from '../../../authenticator'
import { isScheduleSet, isScheduleDayGapless, friendlyNameFromScheduleState } from '../../../common'
import { ajax } from '../../../ajax'
import { getEnglishText } from '../../../dictionary'

import ScheduleRibbonScale from './ScheduleRibbonScale'
import ScheduleRibbonDay from './ScheduleRibbonDay'

import './ScheduleRibbons.css'

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const scheduleTypesConfig = {
  ffr: {
    friendlyName: getEnglishText('common : grid-services'),
    statesUsed: ['ffr-high', 'ffr-low', 'ffr-prep-high', 'ffr-prep-low', 'ffr-backbench'],
    emptyFillState: null,
    renameAllStates: 'grid-services',
    hideWhenNotSpecified: false
  },
  smartstor: {
    friendlyName: getEnglishText('common : smartstor-dfs-schedule'),
    statesUsed: ['normal', 'only-charge', 'only-discharge', 'force-charge', 'force-discharge', 'disable', 'dormant'],
    emptyFillState: null,
    renameAllStates: null,
    hideWhenNotSpecified: false
  },
  customer: {
    friendlyName: getEnglishText('common : manual-schedule'),
    statesUsed: ['normal', 'only-charge', 'only-discharge', 'force-charge', 'force-discharge', 'disable', 'dormant'],
    emptyFillState: 'normal',
    renameAllStates: null,
    hideWhenNotSpecified: false
  },
  eps: {
    friendlyName: getEnglishText('common : eps-schedule'),
    statesUsed: ['eps-disabled', 'eps-enabled'],
    emptyFillState: null,
    renameAllStates: null,
    hideWhenNotSpecified: true
  }
}

function makeEmptySchedulesObject() {
  const emptySchedules = {}
  Object.keys(scheduleTypesConfig).forEach((scheduleType) => (emptySchedules[scheduleType] = null))
  return emptySchedules
}

function makeDefaultShowScheduleTypes() {
  const buffer = []
  Object.keys(scheduleTypesConfig).forEach((scheduleType) => {
    if (!scheduleTypesConfig[scheduleType].hideWhenNotSpecified) buffer.push(scheduleType)
  })
  return buffer
}

function ScheduleRibbons({
  unit,
  scheduleOverride = null,
  scheduleNudge,
  nowCursorStyle = { borderLeft: '2px solid red' },
  showScheduleTypes = makeDefaultShowScheduleTypes(),
  showDays = [...daysOfWeek],
  showScheduleNames = true,
  showScale = true,
  showLegend = true,
  showDayNames = true,
  showEmptyDays = true,
  highlightDay = [],
  dimNonHighlighted = false,
  customScaleSpacingHandler,
  dayClickHandler = null,
  hideOverriddenScheduleTypes = false // true | false | when-no-gaps
}) {
  const idToken = authenticator.getToken()
  const { hardwareId } = unit

  const [schedules, setSchedules] = useState(makeEmptySchedulesObject())

  useEffect(() => {
    if (!scheduleOverride) fetchSchedules()
    return () => {}
  }, [hardwareId, scheduleNudge])

  useEffect(() => {
    if (scheduleOverride) {
      setSchedules({
        customer: scheduleOverride.customer ? scheduleOverride.customer : null,
        ffr: scheduleOverride.ffr ? scheduleOverride.ffr : null,
        smartstor: scheduleOverride.smartstor ? scheduleOverride.smartstor : null,
        eps: scheduleOverride.eps ? scheduleOverride.eps : null
      })
    }
  }, [scheduleOverride])

  function fetchSchedules() {
    ajax
      .fetchUnitSchedules(hardwareId, idToken)
      .then((scheduleData) => {
        setSchedules({
          customer: scheduleData.schedule,
          ffr: scheduleData.ffrSchedule,
          smartstor: scheduleData.smartSchedule,
          eps: scheduleData.epsSchedule
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const stateLegendsToShow = []
  const scheduleTypesToRender = []
  const addScheduleTypeToRender = (scheduleType, schedule = null) => {
    if (!scheduleTypesToRender.includes(scheduleType)) {
      scheduleTypesToRender.push(scheduleType)

      // find all states used for all schedules and all days shown
      if (!schedule) return
      Object.keys(schedule).forEach((scheduleDay) => {
        if (!showDays.includes(scheduleDay)) return // don't check this day of schedule for states used
        for (let i in schedule[scheduleDay])
          if (!stateLegendsToShow.includes(schedule[scheduleDay][i].state)) stateLegendsToShow.push(schedule[scheduleDay][i].state)
      })
    }
  }

  return (
    <div className="ScheduleRibbons">
      <div className="schedule-ribbon-container">
        {daysOfWeek.map((day) => {
          const scheduleTypesToRenderForThisDay = []
          const scheduleTypesWithNoGaps = [] // track if the schedule above has any gaps in it (so we can ignore the `hideOverriddenScheduleTypes` prop if needed)

          showScheduleTypes.forEach((scheduleType, index) => {
            // determine scheduleTypes to  to render:
            if (!showDays.includes(day)) {
              // don't render if day name not specified in showDays in props
              return
            }
            if (!showScheduleTypes.includes(scheduleType)) {
              // don't render if scheduleType not specified in props
              return
            }
            if (index > 0 && hideOverriddenScheduleTypes) {
              if (hideOverriddenScheduleTypes === true && scheduleTypesToRenderForThisDay.length > 0) {
                // don't render if overidden scheduleTypes should be hidden and a higher priority has already declared it will render
                return
              }
              if (hideOverriddenScheduleTypes === 'when-no-gaps' && scheduleTypesWithNoGaps.length > 0) {
                // don't render if overidden scheduleTypes should be hidden and a higher priority has already declared it will render AND there are no gaps in it!
                return
              }
            }
            if (isScheduleSet(schedules[scheduleType], day) || showEmptyDays) {
              // render if schedule is set for this day OR showEmptyDays specified in props
              addScheduleTypeToRender(scheduleType, schedules[scheduleType])
              scheduleTypesToRenderForThisDay.push(scheduleType)
              if (isScheduleDayGapless(schedules[scheduleType], day)) scheduleTypesWithNoGaps.push(scheduleType)
              return
            }
          })

          return (
            <React.Fragment key={day}>
              {scheduleTypesToRenderForThisDay.map((scheduleType) => {
                const thisSchedule = schedules[scheduleType]
                return (
                  <React.Fragment key={scheduleType}>
                    {showScheduleNames && <small className="schedule-type">{scheduleTypesConfig[scheduleType].friendlyName}:</small>}
                    <ScheduleRibbonDay
                      daySchedule={thisSchedule ? thisSchedule[day] : null}
                      emptyFillState={scheduleTypesConfig[scheduleType].emptyFillState}
                      renameAllStates={scheduleTypesConfig[scheduleType].renameAllStates}
                      showDayName={showDayNames ? day : undefined}
                      nowCursorStyle={nowCursorStyle}
                      highlight={highlightDay.includes(day)}
                      dim={dimNonHighlighted && highlightDay && !highlightDay.includes(day)}
                      clickHandler={dayClickHandler}
                    />
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          )
        })}

        {scheduleTypesToRender.length > 0 && showScale && (
          <ScheduleRibbonScale style={{ marginTop: 2, marginLeft: showDayNames ? '2em' : 0 }} customScaleSpacingHandler={customScaleSpacingHandler} />
        )}

        {scheduleTypesToRender.length > 0 && showLegend && stateLegendsToShow.length > 0 && (
          <>
            <div className="schedule-ribbon-key-container">
              {stateLegendsToShow.map((legend, index) => (
                <div key={index} className={`item ${legend}`}>
                  <div className="blip" />
                  {friendlyNameFromScheduleState(legend)}
                </div>
              ))}
            </div>
          </>
        )}

        {scheduleTypesToRender.length === 0 && (
          <>
            <small style={{ opacity: 0.6 }}>{getEnglishText('common : no-schedule-active-for-this-day')}</small>
          </>
        )}
      </div>
    </div>
  )
}

export default ScheduleRibbons
