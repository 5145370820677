import React, { useEffect, useState, useRef } from 'react'
import { Modal, Card, Form, Button, Icon, Header } from 'semantic-ui-react'
import { config, queryString, parseModelCode } from '../../common'
import { getEnglishText } from '../../dictionary'

import './UnitSelector.css'
import { Link } from 'react-router-dom'

const md5 = require('md5')

function UnitSelector({ user, showModal, units, noUnits, callbackSelectedUnits, setShowUnitSelector, device, isStaff }) {
  const qs = queryString()

  const [modalOpen, setModalOpen] = useState(showModal)
  const [filteredUnits, setFilteredUnits] = useState(units ? sortUnits([...units]) : [])
  const [filterText, setFilterText] = useState(qs.hardwareId ? qs.hardwareId : '')
  const [checkedUnits, setCheckedUnits] = useState([])
  const [pageNumber, setPageNumber] = useState(0)

  const [disableClusters, setDisableClusters] = useState(false)
  const [disableSingleUnits, setDisableSingleUnits] = useState(false)

  const [showStaffUI, setShowStaffUI] = useState(false)

  const searchBoxRef = useRef(null)
  const scrollingDivRef = useRef(null)

  const itemsPerPage = 24
  const maxCheckableUnits = 9

  const displayedCount = filteredUnits ? filteredUnits.filter((item) => !item.hidden).length : 0
  const isLastPage = displayedCount < (pageNumber + 2) * itemsPerPage

  useEffect(() => {
    setTimeout(() => {
      try {
        searchBoxRef.current.focus()
      } catch (e) {}
    }, 100)
  }, [showModal, searchBoxRef, filterText])

  useEffect(() => {
    setFilteredUnits(units ? sortUnits([...units]) : [])
    setCheckedUnits([])
    setDisableClusters(false)
    setDisableSingleUnits(false)
    setModalOpen(showModal)
    setFilterText('')
    if (units && qs.hardwareId) filterUnits(qs.hardwareId)
  }, [units, showModal])

  useEffect(() => {
    try {
      scrollingDivRef.current.scrollTop = 0
    } catch (e) {}
  }, [pageNumber, filterText, units, showModal])

  useEffect(() => {}, [checkedUnits])

  function filterUnits(filterText) {
    const filterTextLowerCase = filterText.toLowerCase() || ''

    let first = true
    let filteredUnitList = [...units]

    if (filterTextLowerCase) {
      filteredUnitList = units.map((unit) => {
        const {
          unitId,
          ownerEmail,
          ownerEmail2,
          ownerEmail3,
          ownerEmail4,
          installerEmail,
          groups,
          customName,
          clusterName,
          configId,
          smartScheduleEnabled,
          betaOptIn,
          dfsOptIn,
          model,
          importTariffId,
          exportTariffId
        } = unit

        const parsedModelCode = parseModelCode(model)

        const unitIdMatch = unitId.toLowerCase().indexOf(filterTextLowerCase) > -1
        let contractMatch = false
        let ownerEmailMatch = false
        let installerEmailMatch = false
        let groupNameMatch = false
        let customNameMatch = false
        let clusterNameMatch = false

        let filterP3Match = false
        let filterPVCMatch = false
        let filterP4Match = false
        let filterP5Match = false
        let filterSmartStorMatch = false
        let filterDfsOptInMatch = false
        let filterBetaOptInMatch = false

        if (configId) contractMatch = configId.toLowerCase().indexOf(filterTextLowerCase) > -1

        if (ownerEmail) ownerEmailMatch = ownerEmail.toLowerCase().indexOf(filterTextLowerCase) > -1
        if (ownerEmail2 && !ownerEmailMatch) ownerEmailMatch = ownerEmail2.toLowerCase().indexOf(filterTextLowerCase) > -1
        if (ownerEmail3 && !ownerEmailMatch) ownerEmailMatch = ownerEmail3.toLowerCase().indexOf(filterTextLowerCase) > -1
        if (ownerEmail4 && !ownerEmailMatch) ownerEmailMatch = ownerEmail4.toLowerCase().indexOf(filterTextLowerCase) > -1
        // if (installerEmail) installerEmailMatch = installerEmail.toLowerCase().indexOf(filterTextLowerCase) > -1
        if (customName) customNameMatch = customName.toLowerCase().indexOf(filterTextLowerCase) > -1
        if (clusterName) clusterNameMatch = clusterName.toLowerCase().indexOf(filterTextLowerCase) > -1

        if (Array.isArray(groups) && groups.length) {
          const foundGroups = groups.filter((group) => group.toLowerCase().indexOf(filterTextLowerCase) > -1)
          groupNameMatch = foundGroups.length > 0
        }

        // hidden search options
        if (parsedModelCode.productLineStaff === 'P3' && filterTextLowerCase.toLowerCase().includes('staff:p3')) filterP3Match = true
        if (parsedModelCode.productLineStaff === 'PVCompact' && filterTextLowerCase.toLowerCase().includes('staff:pvc')) filterP3Match = true
        if (parsedModelCode.productLineStaff === 'P4' && filterTextLowerCase.toLowerCase().includes('staff:p4')) filterP3Match = true
        if (parsedModelCode.productLineStaff === 'P5' && filterTextLowerCase.toLowerCase().includes('staff:p5')) filterP3Match = true
        if (smartScheduleEnabled && filterTextLowerCase.toLowerCase().includes('staff:smartstor')) filterSmartStorMatch = true
        if (!smartScheduleEnabled && filterTextLowerCase.toLowerCase().includes('staff:!smartstor')) filterSmartStorMatch = true

        if (importTariffId && filterTextLowerCase.toLowerCase().includes('staff:import-tariff-set')) filterSmartStorMatch = true
        if (!importTariffId && filterTextLowerCase.toLowerCase().includes('staff:!import-tariff-set')) filterSmartStorMatch = true
        if (exportTariffId && filterTextLowerCase.toLowerCase().includes('staff:export-tariff-set')) filterSmartStorMatch = true
        if (!exportTariffId && filterTextLowerCase.toLowerCase().includes('staff:!export-tariff-set')) filterSmartStorMatch = true

        if (dfsOptIn && filterTextLowerCase.toLowerCase().includes('staff:dfsoptin')) filterDfsOptInMatch = true
        if (betaOptIn && filterTextLowerCase.toLowerCase().includes('staff:betaoptin')) filterBetaOptInMatch = true

        const hidden =
          !unitIdMatch &&
          !contractMatch &&
          !ownerEmailMatch &&
          !installerEmailMatch &&
          !groupNameMatch &&
          !customNameMatch &&
          !clusterNameMatch &&
          !filterP3Match &&
          !filterPVCMatch &&
          !filterP4Match &&
          !filterP5Match &&
          !filterSmartStorMatch &&
          !filterDfsOptInMatch &&
          !filterBetaOptInMatch

        let isSelected = false
        if (filterTextLowerCase && first && !hidden) {
          isSelected = true
          first = false
        }

        return {
          ...unit,
          hidden,
          selected: isSelected
        }
      })
    }

    setPageNumber(0)
    setFilterText(filterText)
    setFilteredUnits(sortUnits(filteredUnitList))
  }

  function sortUnits(_units) {
    return _units.sort((a, b) => {
      if (a.clusterName && b.clusterName) {
        // sort by clusterName if it is available
        if (a.clusterName < b.clusterName) return -1
        if (a.clusterName > b.clusterName) return 1
        return 0
      } else if (a.customName && b.customName) {
        // sort by customName if it is available
        if (a.customName < b.customName) return -1
        if (a.customName > b.customName) return 1
        return 0
      } else {
        // otherwise sort by hardwareId
        if (a.hardwareId < b.hardwareId) return -1
        if (a.hardwareId > b.hardwareId) return 1
        return 0
      }
    })
  }

  function printCustomerEmails(unit) {
    const emails = [unit.ownerEmail]
    if (unit.ownerEmail2) emails.push(unit.ownerEmail2)
    if (unit.ownerEmail3) emails.push(unit.ownerEmail3)
    if (unit.ownerEmail4) emails.push(unit.ownerEmail4)
    return emails.map((email, index) => (
      <React.Fragment key={index}>
        <span>{email}</span>
        <br />
      </React.Fragment>
    ))
  }

  function selectUnits(_units = null) {
    const selectedUnits = []

    if (_units) {
      selectedUnits.push(...sortUnits(_units))
    } else if (checkedUnits.length > 0) {
      // callback select checked units
      selectedUnits.push(...sortUnits(checkedUnits))
    } else {
      // callback select first unit
      const firstUnit = filteredUnits.filter((unit) => !unit.hidden)
      selectedUnits.push(firstUnit[0])
    }

    callbackSelectedUnits({ units: selectedUnits })
    setModalOpen(showModal)
  }

  function setUnitChecked(_units, checked, origin) {
    if (!Array.isArray(_units)) _units = [_units]

    let newCheckedUnits = [...checkedUnits]
    for (let i in _units) {
      newCheckedUnits = newCheckedUnits.filter((u) => u.hardwareId !== _units[i].hardwareId)
      if (checked) newCheckedUnits.push(_units[i]) // add to list if checked
    }
    if (newCheckedUnits.length > maxCheckableUnits) return

    setCheckedUnits(newCheckedUnits)
    if (checked) {
      if (origin === 'cluster') {
        setDisableClusters(false)
        setDisableSingleUnits(true)
      }
      if (origin === 'single') {
        setDisableClusters(true)
        setDisableSingleUnits(false)
      }
    } else {
      if (newCheckedUnits.length === 0) {
        setDisableClusters(false)
        setDisableSingleUnits(false)
      }
    }
  }

  function changePage(direction) {
    switch (direction) {
      case -1:
        if (pageNumber > 0) setPageNumber(pageNumber - 1)
        break
      case 1:
        if (!isLastPage) setPageNumber(pageNumber + 1)
        break
      default:
        return
    }
  }

  function UnitList() {
    let counter = -1

    if (!filteredUnits || !filteredUnits[0]) return null

    //////////////
    // CLUSTERS //
    //////////////

    const clustersByHash = {}

    // find clusters

    for (let i = 0; i < units.length; i++) {
      const { clusterUnits, clusterName } = units[i]
      if (!clusterUnits) continue
      const hash = md5(clusterUnits)
      let clusterUnitsParsed = null
      try {
        clusterUnitsParsed = JSON.parse(clusterUnits)
        clustersByHash[hash] = { clusterName, units: clusterUnitsParsed }
      } catch (e) {}
    }
    // create cluster objects

    const clusters = []

    for (let hash in clustersByHash) {
      let hidden = true
      const expandedCluster = []
      const { clusterName, units } = clustersByHash[hash]

      for (let clusterMember in units) {
        const { hardwareId } = units[clusterMember]
        let foundUnit = null
        for (let i in filteredUnits) if (filteredUnits[i]['hardwareId'] === hardwareId) foundUnit = filteredUnits[i]
        if (foundUnit) expandedCluster.push(foundUnit)
        if (!foundUnit || !foundUnit.hidden) hidden = false
      }

      clusters.push({
        hidden, // tells us if every unit in this cluster is hidden by search term
        clusterName,
        clusterHash: hash,
        units: expandedCluster
      })
    }

    // sort clusters
    const sortedClusters = clusters.sort((a, b) => {
      if (a.clusterName < b.clusterName) return -1
      if (a.clusterName > b.clusterName) return 1
      return 0
    })

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Hacky, hard-coded method to allow singular view of clustered units for only a few cuistomer account IDs //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    let allowSingularViewOfCluster = false

    try {
      for (const u of units) {
        if (config.allowSingularViewOfClusterCustomerAccountIds.includes(user?.user?.customerAccountId)) {
          allowSingularViewOfCluster = true
          break
        }
      }
    } catch (error) {}

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////

    return (
      <>
        <Card.Group>
          {/* RENDER CLUSTERS  */}

          {sortedClusters.length > 0 && pageNumber === 0 && (
            <>
              {sortedClusters.map((cluster, index) => {
                const { hidden, clusterHash, clusterName, units } = cluster
                const clusterHardwareIds = units.map((u) => u.hardwareId).sort()
                const clusterCustomNames = units.map((u) => u.customName || u.hardwareId).sort()

                const extendedUnits = units.map((u) => ({ ...u, clusterHash, clusterHardwareIds, clusterUnits: units, clusterSelected: true }))

                if (hidden) return null // item is hidden

                return (
                  <Card
                    className={`pv-primary-background-color unit-cluster ${disableClusters ? 'disabled' : ''}`}
                    key={`cluster-${index}`}
                    onClick={(e) => {
                      if (disableClusters) return
                      if (checkedUnits.length > 0) {
                        // some units have been checked so
                        //  toggle unit checked
                        setUnitChecked(extendedUnits, !checkedUnits.map((u) => u.clusterHash).includes(clusterHash), 'cluster')
                      } else {
                        // no units have been checked so
                        //  select unit
                        selectUnits(extendedUnits)
                      }
                    }}
                  >
                    <Card.Content>
                      <Card.Header>
                        <span className="quote">"</span>
                        {clusterName}
                        <span className="quote">"</span>
                        <br />
                        <small style={{ opacity: 0.5 }}>
                          {clusterCustomNames.map((name, index) => (
                            <React.Fragment key={index}>
                              &nbsp;&nbsp;&nbsp;&nbsp;<span className="quote">"</span>
                              {name}
                              <span className="quote">"</span>
                              <br />
                            </React.Fragment>
                          ))}
                        </small>
                      </Card.Header>
                      {showStaffUI && units[0].ownerEmail && <Card.Description>{units[0].ownerEmail}</Card.Description>}
                      <input
                        className="checkbox"
                        disabled={disableClusters}
                        type="checkbox"
                        checked={checkedUnits.map((u) => u.clusterHash).includes(clusterHash)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          if (disableClusters) return
                          setUnitChecked(extendedUnits, e.target.checked, 'cluster')
                        }}
                        style={{ opacity: checkedUnits.length > 0 ? 0.75 : 0.3 }}
                      />
                    </Card.Content>
                  </Card>
                )
              })}

              <div className="card-separator"></div>
            </>
          )}

          {/* RENDER INDIVIDUAL UNITS */}

          {filteredUnits.map((unit, index) => {
            const { registrationDateUtc, relationship, hidden, unitId, ownerEmail, installerEmail, groups, clusterUnits } = unit

            if (clusterUnits && !isStaff && !allowSingularViewOfCluster) return null // disallow singular unit when part of a cluster (unless logged in as staff)

            if (hidden) return null // item is hidden
            counter++

            if (counter < pageNumber * itemsPerPage) return null // item is before current page
            if (counter + 1 > (pageNumber + 1) * itemsPerPage) return null // item is after current page

            let relationshipLabel = ''

            switch (relationship) {
              case 'owner':
                relationshipLabel = getEnglishText('unit-selector : label-owner')
                break
              case 'staff-visible':
                relationshipLabel = getEnglishText('unit-selector : label-staff-visible')
                break
              case 'installer':
                relationshipLabel = getEnglishText('unit-selector : label-installer')
                break
              case 'group-member':
                relationshipLabel = getEnglishText('unit-selector : label-group-member')
                break
              default:
                break
            }

            return (
              <Card
                className={`single-unit ${unit.selected ? 'selected' : ''} ${disableSingleUnits ? 'disabled' : ''}`}
                key={`unit-${index}`}
                onClick={(e) => {
                  if (disableSingleUnits) return
                  if (checkedUnits.length > 0) {
                    // some units have been checked so
                    //  toggle unit checked
                    setUnitChecked(unit, !checkedUnits.map((u) => u.hardwareId).includes(unit.unitId), 'single')
                  } else {
                    // no units have been checked so
                    //  select unit
                    selectUnits([unit])
                  }
                }}
              >
                <Card.Content>
                  {unit.customName ? (
                    <Card.Header>
                      <span className="quote">"</span>
                      {unit.customName}
                      <span className="quote">"</span>
                      <br />
                      <small style={{ opacity: 0.5 }}>{unit.unitId}</small>
                    </Card.Header>
                  ) : (
                    <Card.Header>{unit.unitId}</Card.Header>
                  )}
                  {showStaffUI && (
                    <Card.Description>
                      {unit.ownerEmail ? printCustomerEmails(unit) : <em>{getEnglishText('unit-selector : no-customer-assigned')}</em>}
                    </Card.Description>
                  )}
                  <div className={`relationship-label ${relationship}`}>{relationshipLabel}</div>

                  <input
                    className="checkbox"
                    disabled={disableSingleUnits}
                    type="checkbox"
                    checked={checkedUnits.map((u) => u.hardwareId).includes(unit.unitId)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      if (disableSingleUnits) return
                      setUnitChecked(unit, e.target.checked, 'single')
                    }}
                    style={{ opacity: checkedUnits.length > 0 ? 0.75 : 0.3 }}
                  />

                  {showStaffUI && Array.isArray(groups) && groups.length ? (
                    <div className={`group-label`}>
                      {groups.map((group, index) => {
                        return (
                          <React.Fragment key={index}>
                            <strong>{group}</strong>
                            <br />
                          </React.Fragment>
                        )
                      })}
                    </div>
                  ) : null}
                </Card.Content>
              </Card>
            )
          })}
        </Card.Group>
      </>
    )
  }

  return (
    <>
      {noUnits ? (
        // No units
        <div id="unit-selector">
          <Modal id="unit-selector-modal" size="tiny" open={modalOpen} centered={false} dimmer="blurring">
            <Modal.Header>{getEnglishText('unit-selector : no-units-modal-title')}</Modal.Header>
            <Modal.Content>
              <Modal.Description>{getEnglishText('unit-selector : no-units-modal-body')}</Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button as={Link} to={'/logout'} primary>
                {getEnglishText('common : sign-out')}
              </Button>
              <Button secondary basic href="mailto:service@powervault.co.uk?subject=Enquiry">
                <Icon name="mail" /> {getEnglishText('common : contact-us')}
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      ) : (
        units &&
        units[0] && (
          // Units assiged to user found
          <Modal
            id="unit-selector-modal"
            size="large"
            open={modalOpen}
            centered={false}
            dimmer="blurring"
            className={checkedUnits.length > 0 ? 'unit-selected' : ''}
          >
            <Modal.Header>{getEnglishText('unit-selector : modal-title')}</Modal.Header>

            {isStaff && !showStaffUI && (
              <Button basic size="tiny" as={Link} className="staff-ui-button" onClick={() => setShowStaffUI(true)}>
                Staff UI
              </Button>
            )}

            <Modal.Content className="search-area">
              <Form>
                {isStaff && showStaffUI && (
                  <div className="staff-filters">
                    <small>Filters :</small>
                    <Button
                      basic
                      size="tiny"
                      content="Clear"
                      onClick={() => {
                        filterUnits('')
                        setFilterText('')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="P3"
                      onClick={() => {
                        filterUnits('staff:p3')
                        setFilterText('staff:p3')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="PVC"
                      onClick={() => {
                        filterUnits('staff:pvc')
                        setFilterText('staff:pvc')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="P4"
                      onClick={() => {
                        filterUnits('staff:p4')
                        setFilterText('staff:p4')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="P5"
                      onClick={() => {
                        filterUnits('staff:p5')
                        setFilterText('staff:p5')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="SMARTSTOR&trade; Enabled"
                      onClick={() => {
                        filterUnits('staff:smartstor')
                        setFilterText('staff:smartstor')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="SMARTSTOR&trade; Disabled"
                      onClick={() => {
                        filterUnits('staff:!smartstor')
                        setFilterText('staff:!smartstor')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="Import Tariff Set"
                      onClick={() => {
                        filterUnits('staff:import-tariff-set')
                        setFilterText('staff:import-tariff-set')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="Import Tariff not Set"
                      onClick={() => {
                        filterUnits('staff:!import-tariff-set')
                        setFilterText('staff:!import-tariff-set')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="Export Tariff Set"
                      onClick={() => {
                        filterUnits('staff:export-tariff-set')
                        setFilterText('staff:export-tariff-set')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="Export Tariff not Set"
                      onClick={() => {
                        filterUnits('staff:!export-tariff-set')
                        setFilterText('staff:!export-tariff-set')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="DFS Opt-in"
                      onClick={() => {
                        filterUnits('staff:dfsoptin')
                        setFilterText('staff:dfsoptin')
                      }}
                    />
                    <Button
                      primary
                      basic
                      size="tiny"
                      content="Beta Opt-in"
                      onClick={() => {
                        filterUnits('staff:betaoptin')
                        setFilterText('staff:betaoptin')
                      }}
                    />
                  </div>
                )}
                <Form.Field>
                  <input
                    placeholder="Search"
                    onChange={(e) => {
                      filterUnits(e.target.value)
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && filteredUnits && filteredUnits.length) {
                        selectUnits()
                      }
                    }}
                    value={filterText}
                    ref={searchBoxRef}
                  />
                </Form.Field>
              </Form>
            </Modal.Content>

            <Modal.Content>
              <div className="scrolling" ref={scrollingDivRef}>
                {/* <ClusterList /> */}
                <UnitList />
              </div>
            </Modal.Content>

            <Modal.Actions>
              <div className="page-buttons">
                <Button
                  secondary
                  basic
                  disabled={pageNumber < 1}
                  content={
                    <>
                      <Icon name="chevron left" />
                      {getEnglishText('common : previous-page')}
                    </>
                  }
                  onClick={() => changePage(-1)}
                  size={device.includes('mobile') ? 'tiny' : null}
                />
                &nbsp;&nbsp;
                <Button
                  secondary
                  basic
                  disabled={isLastPage || disableSingleUnits}
                  content={
                    <>
                      {getEnglishText('common : next-page')}
                      <Icon name="chevron right" />
                    </>
                  }
                  onClick={() => changePage(1)}
                  size={device.includes('mobile') ? 'tiny' : null}
                />
                {/* <span>Page {pageNumber}</span> */}
              </div>

              <div className="select-buttons">
                {checkedUnits.length === 0 && (
                  <Button
                    secondary
                    content={getEnglishText('common : close')}
                    onClick={() => setShowUnitSelector(false)}
                    size={device.includes('mobile') ? 'tiny' : null}
                  />
                )}
                {checkedUnits.length > 0 && (
                  <>
                    <Button
                      secondary
                      content={getEnglishText('common : select-none')}
                      onClick={() => {
                        setCheckedUnits([])
                        setDisableClusters(false)
                        setDisableSingleUnits(false)
                      }}
                      size={device.includes('mobile') ? 'tiny' : null}
                    />
                    <Button
                      primary
                      content={`${
                        checkedUnits.length > 1 ? getEnglishText('unit-selector : select-button-plural') : getEnglishText('unit-selector : select-button')
                      } ${checkedUnits.length > 1 ? `(${checkedUnits.length})` : ''}`}
                      onClick={() => selectUnits()}
                      icon="chevron right"
                      labelPosition="right"
                      size={device.includes('mobile') ? 'tiny' : null}
                    />
                  </>
                )}
              </div>
            </Modal.Actions>
          </Modal>
        )
      )}
    </>
  )
}

export { UnitSelector }
