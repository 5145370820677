import React, { useState, useEffect } from 'react'
import { ajax } from '../../ajax'
import { Message, Icon } from 'semantic-ui-react'
import { authenticator } from '../../authenticator'
import './Announcements.css'

function Announcements() {
  const idToken = authenticator.getToken()

  const [announcements, setAnnouncements] = useState([])

  useEffect(() => {
    ajax
      .fetchAnnouncements(idToken)
      .then((announcementData) => setAnnouncements(announcementData))
      .catch((error) => console.error(error))
  }, [])

  function isDismissed(uid) {
    return localStorage.getItem(`announcementDismissed_${uid}`) ? true : false
  }

  function dismissMessage(uid) {
    setAnnouncements(announcements.filter((announcement) => announcement.announcementUid !== uid))
    localStorage.setItem(`announcementDismissed_${uid}`, 'true')
  }

  let announcementsToDisplay = false
  if (Array.isArray(announcements)) announcementsToDisplay = announcements.filter((announcement) => !isDismissed(announcement.announcementUid)).length > 0

  if (announcementsToDisplay) {
    return (
      <div id="Announcements">
        {Array.isArray(announcements) ? (
          <>
            {announcements.map((announcement, index) => {
              let { announcementUid, announcementType, announcementTitle, announcementBody, announcementLink, announcementIcon } = announcement

              if (!announcementUid) return null
              if (isDismissed(announcementUid)) return null

              return (
                <Message
                  className="pv-dashboard-transparent-message"
                  key={index}
                  info={announcementType === 'info'}
                  warning={announcementType === 'warning'}
                  error={announcementType === 'negative'}
                  onDismiss={() => dismissMessage(announcementUid)}
                  icon
                >
                  <Icon
                    className="mobile-hidden"
                    name={
                      announcementIcon
                        ? announcementIcon
                        : announcementType === 'info'
                        ? 'info circle'
                        : announcementType === 'warning'
                        ? 'warning circle'
                        : announcementType === 'negative'
                        ? 'warning sign'
                        : 'info circle'
                    }
                  />
                  <Message.Content>
                    <Message.Header>{announcementTitle}</Message.Header>
                    <p>{announcementBody}</p>
                    {announcementBody && (
                      <p>
                        <a target="_blank" href={announcementLink} referrerPolicy="no-referrer" rel="noreferrer">
                          {announcementLink}
                        </a>
                      </p>
                    )}
                  </Message.Content>
                </Message>
              )
            })}
          </>
        ) : null}
      </div>
    )
  } else {
    return null
  }
}

export { Announcements }
