import { Header } from 'semantic-ui-react'

const dictionary = {
  english: {
    'common : loading': 'Loading ...',
    'common : or': 'or',
    'common : discard-changes': 'Discard Changes',
    'common : back': 'Back',
    'common : finish': 'Finish',
    'common : cancel': 'Cancel',
    'common : close': 'Close',
    'common : save': 'Save',
    'common : save-changes': 'Save Changes',
    'common : save-settings': 'Save Settings',
    'common : continue': 'Continue',
    'common : unsaved-changes': 'Unsaved Changes',
    'common : saving': 'Saving ...',
    'common : app-title': 'Powervault Portal',
    'common : sign-out': 'Sign Out',
    'common : contact-us': 'Contact Us',
    'common : previous-page': 'Previous Page',
    'common : next-page': 'Next Page',
    'common : select-all': 'Select All',
    'common : select-none': 'Select None',
    'common : grid-services': 'Grid Services',
    'common : smartstor-dfs-schedule': <>SMARTSTOR&trade; / DFS&nbsp;&nbsp;Schedule</>,
    'common : manual-schedule': 'Manual Schedule',
    'common : eps-schedule': 'EPS Schedule',
    'common : no-schedule-active-for-this-day': 'No schedule active for this day',
    'common : status': 'Status',
    'common : enable': 'Enable',
    'common : disable': 'Disable',
    'common : enabled': 'Enabled',
    'common : disabled': 'Disabled',
    'common : less': 'Less',
    'common : more': 'More',
    'common : error': 'Error',
    'common : units': 'units',

    'loader : stage1': 'Loading ...',
    'loader : stage2': 'Loading user data ...',
    'loader : loading': 'Loading ...',
    'loader : taking-longer-than-usual': 'Taking a little longer than usual ...',
    'loader : something-is-wrong': 'Something is wrong, trying again ...',
    'loader : failed-to-load': 'Failed to load.',
    'loader : reload-button': 'Clear cache and reload',

    'nav-bar : select-powervault': (
      <>
        Select<span>&nbsp;Powervault</span>
      </>
    ),

    'send-to-mobile : desktop-title': 'Install the mobile version?',
    'send-to-mobile : desktop-description': "Scan the QR Code below with your phone to add the Powervault Portal to your phone's home screen.",
    'send-to-mobile : mobile-title': 'Install app to your home screen?',
    'send-to-mobile : mobile-description': (
      <>
        <p>To install the Powervault Portal on your phone's home screen please perform the following actions:</p>
        <ol>
          <li>Open your browser's main menu</li>
          <li>Select "Add to Home screen" or "Install app"</li>
          <li>Done!</li>
        </ol>
      </>
    ),

    'send-to-mobile : not-now': 'Not now',
    'send-to-mobile : dont-ask-me-again': "Don't ask me again",

    'unknown-email : modal-title': 'Email address not found',
    'unknown-email : modal-body': (
      <>
        <p>We could not find an account associated with your sign-in details.</p>
        <Header as="h3">Please check the email address entered and try again.</Header>
        <br />
        <p>Alternatively, contact us for more information.</p>
      </>
    ),

    'main-menu : install-details': 'Install Details',
    'main-menu : subscriptions': 'Subscriptions',
    'main-menu : feature-unavailable-title': 'Feature unavailable',
    'main-menu : feature-unavailable-body': <p>You are currently signed in as an installer or staff member.</p>,
    'main-menu : more': 'More ...',
    'main-menu : release-notes': 'Release Notes',

    'unit-selector : modal-title': 'Select a Powervault',
    'unit-selector : label-owner': 'Your Powervault',
    'unit-selector : label-staff-visible': 'Visible to Powervault staff',
    'unit-selector : label-installer': 'Installed / Registered by you',
    'unit-selector : label-group-member': 'Group:',
    'unit-selector : select-button': 'Select Powervault',
    'unit-selector : select-button-plural': 'Select Powervaults',
    'unit-selector : no-customer-assigned': 'No customer assigned',
    'unit-selector : no-units-modal-title': 'No Powervault Found!',
    'unit-selector : no-units-modal-body': (
      <>
        <p>There are currently no Powervaults associated with your sign-in details.</p>
        <Header as="h3">Please scan the QR code affixed to your Powervault.</Header>
        <br />
        <p>Alternatively, contact us for more information.</p>
      </>
    ),

    'temporary-overrides : title': 'Temporary Override',
    'temporary-overrides : override': 'Override',
    'temporary-overrides : ends': 'Ends',
    'temporary-overrides : for': 'for',
    'temporary-overrides : until': 'until',
    'temporary-overrides : extending': 'Extending',
    'temporary-overrides : extend-time': 'Extend time',
    'temporary-overrides : end-now': 'End now',
    'temporary-overrides : starting': 'Starting',
    'temporary-overrides : force-charge': 'Force Charge',
    'temporary-overrides : force-discharge': 'Force Discharge',
    'temporary-overrides : normal-override': 'Normal Override',
    'temporary-overrides : disable-override': 'Disallow Battery Discharge',
    'temporary-overrides : force-charge-description': (
      <>
        Your Powervault will attempt to <strong>Force Charge</strong>,
        <br />
        overriding SMARTSTOR&trade; and manual schedules.
      </>
    ),
    'temporary-overrides : force-discharge-description': (
      <>
        Your Powervault will attempt to <strong>Force Discharge</strong>,
        <br />
        overriding SMARTSTOR&trade; and manual schedules.
      </>
    ),
    'temporary-overrides : normal-override-description': (
      <>
        Your Powervault will enter <strong>Normal Override</strong> mode,
        <br />
        overriding SMARTSTOR&trade; and manual schedules.
      </>
    ),
    'temporary-overrides : disable-override-description': (
      <>
        Your Powervault will enter <strong>Only Charge</strong> mode,
        <br />
        overriding SMARTSTOR&trade; and manual schedules.
        <br />
        (For example, to stop your battery charging your EV.)
      </>
    ),

    'operating-states : only-charge': 'Only Charge',
    'operating-states : force-charge': 'Force Charge',
    'operating-states : only-discharge': 'Only Discharge',
    'operating-states : force-discharge': 'Force Discharge',
    'operating-states : normal': 'Normal',
    'operating-states : dormant': 'Dormant',
    'operating-states : disabled': 'Disabled',
    'operating-states : ffr-high': 'FFR High',
    'operating-states : ffr-low': 'FFR Low',
    'operating-states : ffr-prep-high': 'FFR Prep High',
    'operating-states : ffr-prep-low': 'FFR Prep Low',
    'operating-states : eps-enabled': 'EPS Enabled',
    'operating-states : eps-disabled': 'EPS Disabled',

    'alerts : dno-override-received-title': 'DNO Override received',
    'alerts : dno-override-received-body': 'Please contact support',
    'alerts : eps-grid-loss-title': 'EPS Grid Loss',
    'alerts : eps-grid-loss-body': 'EPS Grid Loss',
    'alerts : eps-over-consumption-title': 'EPS Over Consumption',
    'alerts : eps-over-consumption-body': 'EPS Over Consumption',
    'alerts : eps-low-soc-title': 'EPS Low SoC',
    'alerts : eps-low-soc-body': 'EPS Low SoC',

    'live-view : title': 'Live View',
    'live-view : subtitle': 'Live data from your Powervault',
    'live-view : state-of-charge': 'State of charge',
    'live-view : no-unit-id': 'No unit ID(s)',
    'live-view : too-long': <p>Live data from your Powervault is taking a little longer than usual to reach us...</p>,
    'live-view : way-too-long': (
      <>
        <p>Live data from your Powervault is taking longer than usual to reach us...</p>
        <p>If this keeps happening please verify your Powervault's connection to the internet.</p>
      </>
    ),
    'live-view : grid-clamp-diconnected-title': 'Grid Clamp Disconnected',
    'live-view : grid-clamp-diconnected-body':
      'Your Powervault has lost communication with the grid clamp. Please verify grid clamp connection or contact customer support.',

    'weather : postcode': 'Postcode',
    'weather : enter-your-postcode': 'Enter your postcode for weather info',
    'weather : edit-your-postcode': 'Edit your postcode',
    'weather : finding-weather-for': 'Finding weather for',
    'weather : may-take-a-few-minutes': 'This may take a few minutes',
    'weather : the-app-will-reload': 'The app will now reload',
    'weather : invalid-postcode': 'Invalid postcode entered',
    'weather : unknown-error': 'An unknown error occurred, please try again later',

    'charts : title': 'Charts',
    'charts : subtitle': "Your Powervault's charts",
    'charts : missing-data': 'Missing Data',
    'charts : no-internet': 'No Internet',
    'charts : unit-id-changed': 'Unit ID Changed',
    'charts : grid-clamp-disconnected': 'Grid Clamp Disconnected',
    'charts : no-unit-id': 'No unit ID(s)',

    'totals : title': 'Totals',
    'totals : today': 'Today',
    'totals : this-week': 'This week',
    'totals : this-month': 'This month',
    'totals : this-year': 'This year',
    'totals : yesterday': 'Yesterday',
    'totals : last-week': 'Last week',
    'totals : last-month': 'Last month',
    'totals : last-year': 'Last year',
    'totals : solar': 'Solar',
    'totals : solar-generated': 'Generated',
    'totals : solar-consumed': 'Consumed',
    'totals : grid': 'Grid',
    'totals : grid-import': 'Import',
    'totals : grid-export': 'Export',
    'totals : home': 'Home',
    'totals : home-consumed': 'Consumed',
    'totals : battery': 'Battery',
    'totals : battery-discharge': 'Discharge',
    'totals : earliest-data-is-from': 'Earliest data available is from',

    'install-details : custom-name-invalid': 'Please use alphanumeric characters only and use fewer than 45 characters.',
    'install-details : custom-name-placeholder': 'Custom Name',
    'install-details : set-a-custom-name': 'Set a custom name',
    'install-details : label-battery-capacity': 'Battery Capacity',
    'install-details : label-firmware': 'Firmware',
    'install-details : label-id': 'ID',
    'install-details : label-modal-code': 'Model Code',
    'install-details : label-contract-id': 'Contract ID',
    'install-details : label-hotspot-key': 'Hotspot Password',
    'install-details : nothing-selected-title': 'Nothing selected',
    'install-details : nothing-selected-body': <p>Select a Powervault to view details</p>,

    'settings : title': 'Settings',
    'settings : description': "Your Powervault's settings",
    'settings : manunal-schedule': 'Manual Schedule',
    'settings : manunal-schedule-description': "Set a schedule for your Powervault's operation mode.",
    'settings : manunal-schedule-edit': 'Edit Manual Schedule',
    'settings : manunal-schedule-cluster-edit': 'Edit Cluster Manual Schedule',

    'settings : smartstor': <>SMARTSTOR&trade;</>,
    'settings : smartstor-description': "Automatically set the best schedule for your Powervault based on tariff prices and your household's energy usage.",
    'settings : smartstor-edit': 'Edit Manual Schedule',
    'settings : smartstor-cluster-edit': 'Edit Cluster Manual Schedule',
    'settings : smartstor-set-up': <>Set up SMARTSTOR&trade;</>,
    'settings : smartstor-set-up-cluster': <>Set up cluster SMARTSTOR&trade;</>,
    'settings : smartstor-settings': <>SMARTSTOR&trade; Settings</>,
    'settings : smartstor-subscribe-to-powervault-plus': 'Subscribe to Powervault Plus',
    'settings : dfs': (
      <>
        National Grid
        <br />
        Demand Flexibility Services
      </>
    ),
    'settings : dfs-set-up': 'Set up DFS',

    'settings : eps-schedule': 'EPS Schedule',
    'settings : eps-schedule-description': 'Set a daily schedule for the Emergency Power Socket and set the reserve capacity.',
    'settings : eps-schedule-edit': 'Edit EPS Schedule',

    'settings : led-settings': 'LED Settings',
    'settings : led-settings-description': 'Change the style and animation pattern of the lights on your Powervault.',
    'settings : led-settings-edit': 'LED Settings',

    'manual-schedule : manual-schedule': 'Manual Schedule',
    'manual-schedule : cluster-schedule': 'Cluster Manual Schedule',
    'manual-schedule : conflicting-schedule': 'Conflicting schedule detected!  Please ensure each schedule item has a unique time.',
    'manual-schedule : new-schedule-item': 'New Schedule Item',
    'manual-schedule : no-schedule-set-for-day': 'No schedule is set for this day.',
    'manual-schedule : save-failed-title': 'Could not save schedule.',
    'manual-schedule : save-failed-body': (
      <>
        Your Powervault appears to be offline.
        <br />
        If this problem persists please{' '}
        <a href="mailto:service@powervault.co.uk?subject=Portal+3:+Could+Not+Save+Schedule" target="_blank">
          contact us
        </a>
      </>
    ),
    'manual-schedule : schedule-copied-to': 'Schedule copied to',
    'manual-schedule : schedule-saved': 'Schedule saved.',
    'manual-schedule : schedule-item-deleted': 'Schedule item deleted.',
    'manual-schedule : schedule-cleared': 'schedule cleared',
    'manual-schedule : select-a-schedule-item': 'Select a schedule item',
    'manual-schedule : set-state': 'Set state',
    'manual-schedule : unit-state': 'Unit State',
    'manual-schedule : at': 'At',
    'manual-schedule : clear-day': 'Clear Day',
    'manual-schedule : copy-day-to': 'Copy Day to ...',
    'manual-schedule : copy-to-all-days': 'Copy To All Days',
    'manual-schedule : copy-to-weekdays': 'Copy To Weekdays',
    'manual-schedule : copy-to-weekends': 'Copy To Weekends',
    'manual-schedule : schedule-has-unsaved-changes': <p>Your schedule has unsaved changes.</p>,
    'manual-schedule : confirm-weekly-schedule': 'Confirm Weekly Schedule',
    'manual-schedule : confirm-weekly-schedule-description': <p>You have made the following changes:</p>,

    'eps-schedule : modal-title': 'EPS Schedule',
    'eps-schedule : conflicting-schedule': 'Conflicting schedule detected!  Please ensure each schedule item has a unique time.',
    'eps-schedule : new-schedule-item': 'New Schedule Item',
    'eps-schedule : select-a-schedule-item': 'Select a schedule item',
    'eps-schedule : set-state': 'Set state',
    'eps-schedule : clear-day': 'Clear Day',
    'eps-schedule : copy-to-all-days': 'Copy To All Days',
    'eps-schedule : reserved-capacity': (
      <>
        Reserved
        <br />
        Capacity:
      </>
    ),
    'eps-schedule : schedule-has-unsaved-changes': <p>Your schedule has unsaved changes.</p>,
    'eps-schedule : no-schedule-set-for-day': 'No schedule is set for this day.',
    'eps-schedule : save-failed-title': 'Could not save schedule.',
    'eps-schedule : schedule-copied-to-all-days': 'Schedule copied to all days.',
    'eps-schedule : schedule-saved': 'Schedule saved.',
    'eps-schedule : schedule-item-deleted': 'Schedule item deleted.',
    'eps-schedule : save-failed-body': (
      <>
        Your Powervault appears to be offline.
        <br />
        If this problem persists please{' '}
        <a href="mailto:service@powervault.co.uk?subject=Portal+3:+Could+Not+Save+Schedule" target="_blank" rel="noreferrer" referrerPolicy="no-referrer">
          contact us
        </a>
      </>
    ),

    'smart-schedule : title': <>SMARTSTOR&trade;</>,
    'smart-schedule : title-cluster': <>Cluster SMARTSTOR&trade;</>,
    'smart-schedule : description': (
      <>
        <p>
          SMARTSTOR&trade; automatically sets the best schedule for your Powervault based on changing tariff prices and your household's energy usage, including
          projected solar generation.
        </p>

        <p>Please complete the form below to enable SMARTSTOR&trade; on your Powervault.</p>
      </>
    ),
    'smart-schedule : no-tariff': 'No tariff',
    'smart-schedule : time-of-used-tariff': 'Time of Use tariff',
    'smart-schedule : dynamic-tariff': 'Dynamic tariff',
    'smart-schedule : select-import-tariff': 'Select Import Tariff:',
    'smart-schedule : no-import-tariff-selected': 'No Import Tariff selected',
    'smart-schedule : select-export-tariff': 'Select Export Tariff:',
    'smart-schedule : no-export-tariff-selected': 'No Export Tariff selected',
    'smart-schedule : no-export-tariff': 'No Export Tariff',
    'smart-schedule : please-first-disable': <>Please first disable SMARTSTOR&trade; to edit these settings.</>,
    'smart-schedule : overridden-by-dfs': (
      <>
        Some settings are overridden because <strong>Demand Flexibility Service (DFS)</strong> is enabled.
      </>
    ),
    'smart-schedule : status': 'Status',
    'smart-schedule : enabled': 'Enabled',
    'smart-schedule : disabled': 'Disabled',
    'smart-schedule : enabled-can-be-overridden': (
      <>
        Enabled&nbsp;&nbsp;<small>(can be overriden by DFS)</small>
      </>
    ),
    'smart-schedule : changes-saved-title': 'Changes Saved',
    'smart-schedule : changes-saved-description': (
      <>
        <p>Your SMARTSTOR&trade; preferences have been saved.</p>
        <p>
          <strong>Changes can take up to 24 hours to apply.</strong>
        </p>
        <p>
          SMARTSTOR&trade; predicts operating schedules based on your past energy usage. Its ability to predict gets better over time. If you are a new
          customer, please allow time for your prediction model to mature.
        </p>
      </>
    ),
    'smart-schedule : understood': 'Understood',

    'dfs : modal-title': <>SMARTSTOR&trade; DFS</>,
    'dfs : modal-content': (
      <>
        <p>
          <strong>WARNING: You must already have a relevant energy tariff from your energy supplier to benefit from DFS.</strong> If you select our
          SMARTSTOR&trade; DFS setting and you do not have a relevant tariff, you may lose money as you won’t receive any reward from your supplier, and related
          battery actions may cause you to import additional energy costing you money.
        </p>

        <p>
          In order to participate in the Demand Flexibility Service, you must have a smart meter that operates in smart mode, so that your drop in consumption
          over the specific time period can be measured.
        </p>

        <p>
          Also, you must have a relevant energy tariff with an energy supplier that has signed up to take part in the Demand Flexibility Service. Different
          energy suppliers are approaching this differently, so if you are in any doubt please get in contact with your energy supplier first to see whether or
          not you can benefit from the Demand Flexibility Service.
        </p>
      </>
    ),
    'dfs : select-your-dfs-supplier': 'Select your DFS Supplier:',
    'dfs : changes-saved-title': 'Changes Saved',
    'dfs : changes-saved-content': (
      <>
        <p>Your DFS preferences have been saved.</p>
        <p>
          <strong>Changes can take up to 24 hours to apply.</strong>
        </p>
      </>
    ),

    'led-settings : modal-title': 'LED Settings',
    'led-settings : save-failed-title': 'Could not save settings.',
    'led-settings : settings-saved': 'Settings saved.',
    'led-settings : select-an-animation': "Select an animation style for the Powervault's LEDs:",
    'led-settings : save-failed-body': (
      <>
        <br />
        Your Powervault appears to be offline.
        <br />
        <br />
        If this problem persists please{' '}
        <a href="mailto:service@powervault.co.uk?subject=Portal+3:+Could+Not+Save+Settings" target="_blank">
          contact us
        </a>
      </>
    ),
    'led-settings : bright-fast-animation': 'Bright Fast Animation',
    'led-settings : bright-medium-animation': 'Bright Medium Animation',
    'led-settings : bright-slow-animation': 'Bright Slow Animation',
    'led-settings : bright-static-display': 'Bright Static Display',
    'led-settings : dark-fast-animation': 'Dark Fast Animation',
    'led-settings : dark-medium-animation': 'Dark Medium Animation',
    'led-settings : dark-slow-animation': 'Dark Slow Animation',
    'led-settings : dark-static-display': 'Dark Static Display',
    'led-settings : disabled': 'Disabled',
    'led-settings : disco-mode!': 'Disco Mode!',

    'quick-start : something-went-wrong': 'Something went wrong, please contact Customer Service',
    'quick-start : value-is-invalid': 'value is invalid',
    'quick-start : new-account': 'New Account',
    'quick-start : create-your-new-portal-account': 'Create your new Portal account',
    'quick-start : about-you': 'About You',
    'quick-start : first-name': 'First Name',
    'quick-start : last-name': 'Last Name',
    'quick-start : install-location': 'Install Location',
    'quick-start : address-line-1': 'Address Line 1',
    'quick-start : address-line-2': 'Address Line 2',
    'quick-start : city-town': 'City / Town',
    'quick-start : postcode': 'Postcode',
    'quick-start : phone-number': 'Phone Number',
    'quick-start : additional-users': 'Additional Users',
    'quick-start : additional-users-description': (
      <p>
        Optionally, if you would like to grant other people access to your Portal, please enter their email addresses. <br />
        <span style={{ opacity: 0.5 }}>
          <strong>(They will each need to sign up to the Portal afterwards)</strong>
        </span>
      </p>
    ),
    'quick-start : additional-email': 'Email address',
    'quick-start : add-product': 'Add Product',
    'quick-start : add-product-description': 'Add a new product to your account',
    'quick-start : personalise': 'Personalise',
    'quick-start : personalise-description': <p>You can give your new Powervault a personalised name!</p>,
    'quick-start : custom-name': (
      <>
        Custom Name&nbsp;&nbsp;<span style={{ opacity: 0.4 }}>(Optional, this can be changed later)</span>
      </>
    ),
    'quick-start : custom-name-placeholder': 'My Powervault',
    'quick-start : all-done': 'All done!',
    'quick-start : all-done-description': 'Product added to account',
    'quick-start : continue-to-portal': 'Click Continue to go to the Portal',

    'warranty : activate-product-warranty': 'Activate Product Warranty',
    'warranty : please-activiate-warranty': 'Please continue to activate your warranty for the following products:',
    'warranty : warranty-activated': 'Product Warranty Activated',
    'warranty : thank-you': 'Thank you for activating your warranty!',
    'warranty : activate-warranty-button': 'Activate Warranty',

    'beta-opt-in : modal-title': 'Beta Participation',
    'beta-opt-in : modal-content': (
      <>
        <p>Join our beta program if you would like to try out new features before they are officially released.</p>
        <p>
          <strong>Features in beta may not work entirely as expected; your participation and feedback helps us improve!</strong>
        </p>
        <p>You can leave the beta program at any time.</p>
      </>
    ),
    'beta-opt-in : icon-text': 'beta opt-in',
    'beta-opt-in : join-beta-program': 'Join beta program',
    'beta-opt-in : could-not-load': 'Could not load',

    'release-notes : title': 'Release Notes',
    'release-notes : new-features': 'New Features',
    'release-notes : improvements': 'Improvements',
    'release-notes : bug-fixes': 'Bug Fixes',
    'release-notes : known-issues': 'Known Issues',

    'error-page : 404-title': '404',
    'error-page : 404-description': 'Page not found!',
    'error-page : general-title': 'Something went wrong',
    'error-page : general-description': 'We are aware of this problem and are working hard to fix it!',

    'footer : facebook-link': 'https://www.facebook.com/100063516371759/',
    'footer : instagram-link': 'https://www.instagram.com/powervault_ltd/',
    'footer : linkedin-link': 'https://www.linkedin.com/company/powervault-ltd/',
    'footer : privacy-link': 'https://www.powervault.co.uk/privacy-policy/',
    'footer : terms-link': 'https://www.powervault.co.uk/terms-conditions/',

    'help : title': <>Help &amp; FAQ</>
  }
}

const getEnglishText = (key) => {
  const value = dictionary['english'][key]
  if (value !== undefined) return value
  else {
    console.error('Missing dictionary entry:', key)
    return <span style={{ backgroundColor: 'yellow' }}>`dictionary[english][{key}]`</span>
  }
}

export default dictionary
export { getEnglishText }
