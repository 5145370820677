import React from 'react'
import { Message, Icon } from 'semantic-ui-react'
import { ResponsiveContainer, ComposedChart, AreaChart, Area, Line, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from 'recharts'
import { getEnglishText } from '../../dictionary'

import './Chart.css'

function CustomizedLabel(props) {
  const { x, y, stroke, value, index, nowRowIndex } = props

  if (index !== nowRowIndex) return null

  return (
    <text x={x} y={y} dy={3} dx={15} fill="rgba(0, 157, 156, 0.7)" fontSize={10} textAnchor="middle">
      {value} %
    </text>
  )
}

class Chart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  areaChart = (chartData, chartOptions) => {
    const areas = chartOptions.dataSets.map((dataSet) => {
      switch (dataSet.displayAs) {
        case 'line':
          return (
            <Line
              dot={false}
              // strokeDasharray="4"
              strokeWidth={2}
              // strokeLinecap="round"
              key={dataSet.key}
              type={dataSet.type}
              dataKey={dataSet.key}
              stroke={dataSet.strokeColor}
              yAxisId={dataSet.yAxisId}
              unit={dataSet.unit}
              fill={dataSet.fillColor}
              // legendType="rect"
              // name="Amount Spent"
              // label={<CustomizedLabel nowRowIndex={this.props.nowRowIndex} />} // TODO: uncomment to display SoC label at current time
            />
          )
        case 'area':
        default:
          return (
            <Area
              type={dataSet.type}
              key={dataSet.key}
              dataKey={dataSet.key}
              stackId="1"
              stroke={dataSet.strokeColor}
              yAxisId={dataSet.yAxisId}
              fill={dataSet.fillColor}
              unit={dataSet.unit}
              fillOpacity="1"
            />
          )
      }
    })

    const CustomTooltip = (data) => {
      if (data.active) {
        if (data.payload && data.payload.length) {
          const { label } = data
          const missingData = data.payload[0].payload.missingData
          const noInternet = data.payload[0].payload.noInternet
          const hardwareIdChanged = data.payload[0].payload.hardwareIdChanged
          const gridClampDisconnected = data.payload[0].payload.gridClampDisconnected

          return (
            <div className="tooltip">
              <strong>{label}</strong>
              {data.payload.map((column) => {
                return (
                  <span key={column.name}>
                    <span className="label" style={{ color: column.fill }}>
                      {column.name}:
                    </span>
                    <span className="value">{column.value}</span>
                    <span className="unit">{column.unit}</span>
                  </span>
                )
              })}
              {missingData && <span className="missingData">{getEnglishText('charts : missing-data')}</span>}
              {noInternet && <span className="noInternet">{getEnglishText('charts : no-internet')}</span>}
              {hardwareIdChanged && <span className="hardwareIdChanged">{getEnglishText('charts : unit-id-changed')}</span>}
              {gridClampDisconnected ? <span className="gridClampDisconnected">{getEnglishText('charts : grid-clamp-disconnected')}</span> : null}
            </div>
          )
        } else {
          // can we return a "No Data" tooltip here?
        }
      } else {
        return <React.Fragment />
      }
    }

    // find last non-dummy item
    const nowRow = chartData.filter((row) => row.isNow)[0]
    const missingDataRows = chartData.filter((row) => row.missingData)
    const noInternetRows = chartData.filter((row) => row.noInternet)
    const hardwareIdChangedRows = chartData.filter((row) => row.hardwareIdChanged)
    const gridClampDisconnectedRows = chartData.filter((row) => row.gridClampDisconnected)

    function renderLengend(props) {
      const { payload } = props
      return (
        <div className="legend">
          {payload.map((item, index) => (
            // legendColor
            <div key={`item-${index}`} className={`legend-item ${item.dataKey}`}>
              <Icon name="circle" style={{ color: item?.payload?.fill || 'black' }} />
              <span style={{ color: item?.payload?.fill || 'black' }}>{item.value}</span>
            </div>
          ))}
        </div>
      )
    }

    return (
      <ResponsiveContainer height={chartOptions.height ? chartOptions.height : 200} width="100%" key="1">
        <ComposedChart margin={chartOptions.margin ? chartOptions.margin : {}} data={chartData}>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {chartOptions.xAxis && (
            <XAxis
              dataKey="name"
              ticks={[
                '00:00',
                '01:00',
                '02:00',
                '03:00',
                '04:00',
                '05:00',
                '06:00',
                '07:00',
                '08:00',
                '09:00',
                '10:00',
                '11:00',
                '12:00',
                '13:00',
                '14:00',
                '15:00',
                '16:00',
                '17:00',
                '18:00',
                '19:00',
                '20:00',
                '21:00',
                '22:00',
                '23:00'
              ]}
            />
          )}
          {chartOptions.yAxis.map((y, index) => {
            return (
              <YAxis
                key={index}
                allowDecimals={false}
                formatter={(value) => 'test'}
                type="number"
                yAxisId={y.yAxisId}
                orientation={y.yAxisId === 'right' ? 'right' : 'left'}
                domain={y.domain}
                hide={y.hide}
              />
            )
          })}

          {chartOptions.tooltip && <Tooltip content={CustomTooltip} />}

          <Legend content={renderLengend} />

          {nowRow && <ReferenceLine x={nowRow.name} stroke="black" strokeDasharray="3 3" yAxisId="left" />}

          {noInternetRows &&
            noInternetRows.map((row, index) => <ReferenceLine x={row.name} stroke="rgba(0,0,0,0.08)" key={`noInternet_${index}`} yAxisId="left" />)}

          {missingDataRows &&
            missingDataRows.map((row, index) => <ReferenceLine x={row.name} stroke="rgba(0,0,0,0.08)" key={`missingData_${index}`} yAxisId="left" />)}

          {gridClampDisconnectedRows &&
            gridClampDisconnectedRows.map((row, index) => (
              <ReferenceLine x={row.name} stroke="rgba(255,0,0,0.08)" key={`gridClampDisconnected_${index}`} yAxisId="left" />
            ))}

          {hardwareIdChangedRows &&
            hardwareIdChangedRows.map((row, index) => (
              <ReferenceLine x={row.name} stroke="rgb(47, 177, 21)" strokeWidth={5} key={`hardwareIdChanged_${index}`} yAxisId="left" />
            ))}

          {areas}
        </ComposedChart>
      </ResponsiveContainer>
    )
  }

  render() {
    const { chartData, chartOptions } = this.props

    let buffer = []

    switch (chartOptions.type) {
      case 'area':
        buffer.push(this.areaChart(chartData, chartOptions))
        break
      default:
        buffer.push(
          <Message negative>
            <p>No chart type specified.</p>
          </Message>
        )
    }

    return <div id="chart">{buffer}</div>
  }
}

export { Chart }
